import { Avatar, Button, Grid, Paper, TextField, Typography, InputAdornment } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PersonIcon from '@mui/icons-material/Person'

export default function ForgotPasswordCard({ setUsername, usernameError, handleForgotPassword }) {
    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleForgotPassword()
        }
    }
    return (
        <Grid>
            <Paper
                elevation={10}
                sx={{
                    padding: 10,
                    minHeight: '200px',
                    maxHeight: '720px',
                    minWidth: '300px',
                    maxWidth: '500px',
                    margin: '5vh auto'
                }}>
                <Grid align="center">
                    <Avatar sx={{ backgroundColor: '#1B75D2', height: '70px', width: '70px', marginBottom: '20px' }}>
                        <LockOutlinedIcon fontSize="large" />
                    </Avatar>
                    <Typography variant="h6" gutterBottom>
                        Please insert your email
                    </Typography>
                </Grid>

                <TextField
                    autoFocus
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{ marginTop: 5, marginBottom: 5, display: 'block' }}
                    label="Email"
                    placeholder="Write your email address here"
                    variant="outlined"
                    fullWidth
                    required
                    error={usernameError}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon color="primary" />
                            </InputAdornment>
                        )
                    }}
                />

                <Button
                    onClick={handleForgotPassword}
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                        backgroundColor: '#1B75D2',
                        color: 'white',
                        '&:hover': { backgroundColor: '#2085ea' },
                        height: 60,
                        borderRadius: 5
                    }}>
                    Send verification code
                </Button>
            </Paper>
        </Grid>
    )
}
