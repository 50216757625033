import { Grid, Link, Paper, Stack, Typography } from '@mui/material'

export default function Welcome({ headline }) {
    return (
        <div>
            <Typography variant="h3" align="center" sx={{ pt: '2%' }}>
                {headline}
            </Typography>

            <Grid container height="400px" spacing={4} sx={{ px: '10%', pt: '5%' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper component={Stack} direction="column" justifyContent="center" align="center">
                        <Link href="/quality-compare" color="primary" variant="h6">
                            Quality-Compare tool
                        </Link>
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper component={Stack} direction="column" justifyContent="center" align="center">
                        <Link href="/quality-diagnosable" color="primary" variant="h6">
                            Quality-Diagnosable tool
                        </Link>
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper component={Stack} direction="column" justifyContent="center" align="center">
                        <Link href="/labeling-sonographer" color="primary" variant="h6">
                            Sonographer Labeling tool
                        </Link>
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper component={Stack} direction="column" justifyContent="center" align="center">
                        <Link href="/labeling-cardiologist" color="primary" variant="h6">
                            Cardiologist Labeling tool
                        </Link>
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper component={Stack} direction="column" justifyContent="center" align="center">
                        <Link href="/labeling-quality20" color="primary" variant="h6">
                            Quality 2.0 Labeling tool
                        </Link>
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper component={Stack} direction="column" justifyContent="center" align="center">
                        <Link href="/anatomy-quality" color="primary" variant="h6">
                            Anatomy Quality Labeling tool
                        </Link>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}
