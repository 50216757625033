import { useState, useEffect, useRef } from 'react'
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js'
import { Scatter, getElementAtEvent } from 'react-chartjs-2'
ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)

export default function LabelingGraph({ sequenceData, currentFrame, setCurrentFrame, setIsClipPlaying }) {
    const [graphDataAndOptions, setGraphDataAndOptions] = useState(null)
    const [isDragging, setIsDragging] = useState(false)
    const graphOptions = useRef(null)
    const chartRef = useRef()
    const startX = useRef()
    const startY = useRef()
    const delta = 6

    useEffect(() => {
        document.addEventListener('wheel', handleScroll)

        return function cleanup() {
            document.removeEventListener('wheel', handleScroll)
        }
    }, [])

    function buildGraph() {
        const data = sequenceData?.firstGraph?.data
        // add a new dataset for the current frame
        const point = data.datasets[0].data[currentFrame]
        data.datasets.push({
            label: 'Current Frame',
            order: 6,
            data: [point],
            pointBackgroundColor: '#2196f3',
            pointRadius: 8
        })
        const options = {
            title: {
                display: true,
                text: sequenceData?.firstGraph?.title
            },
            scales: {
                x: {
                    type: 'linear',
                    position: 'bottom'
                },
                y: {
                    type: 'linear',
                    ticks: {
                        min: sequenceData?.firstGraph?.min,
                        max: sequenceData?.firstGraph?.max,
                        display: false
                    }
                }
            },
            animation: 'none',
            events: [],
            maintainAspectRatio: false
        }
        setGraphDataAndOptions({ data: data, options: options })
        graphOptions.current = options
    }

    useEffect(() => {
        if (!sequenceData || !sequenceData.firstGraph) return
        buildGraph()
    }, [sequenceData])

    function drag(evt) {
        isDragging && setCurrentFrameFromGraph(evt)
    }

    function scrollRight() {
        setCurrentFrame((prevFrame) => {
            if (prevFrame === sequenceData?.images?.length - 1) return prevFrame
            return prevFrame + 1
        })
    }

    function scrollLeft() {
        setCurrentFrame((prevFrame) => {
            if (prevFrame === 0) return prevFrame
            return prevFrame - 1
        })
    }

    function setCurrentFrameFromGraph(e) {
        const point = getElementAtEvent(chartRef.current, e)[0]
        if (!point) return
        setCurrentFrame(point.index)
    }

    useEffect(() => {
        if (currentFrame === null || currentFrame === undefined) return
        drawCurrentFrameOnGraph()
    }, [currentFrame])

    function drawCurrentFrameOnGraph() {
        const data = sequenceData?.firstGraph?.data
        const point = data?.datasets[0]?.data[currentFrame]
        const newDataset = {
            label: 'Current Frame',
            order: 6,
            data: [point],
            pointBackgroundColor: '#2196f3',
            pointRadius: 8
        }
        data.datasets.pop()
        data.datasets.push(newDataset)
        if (chartRef.current === undefined) return
        chartRef.current.data = data
        chartRef.current.options = graphOptions.current
        chartRef.current.update('none')
    }

    function handleMouseDown(e) {
        setIsClipPlaying(false)
        startX.current = e.pageX
        startY.current = e.pageY
        !isDragging && setIsDragging(true)
    }

    function handleMouseUp(e) {
        const diffX = Math.abs(e.pageX - startX.current)
        const diffY = Math.abs(e.pageY - startY.current)
        if (diffX < delta && diffY < delta) {
            // click event
            setIsDragging(false)
            setCurrentFrameFromGraph(e)
        } else isDragging && setIsDragging(false)
    }

    function handleScroll(event) {
        setIsClipPlaying(false)
        checkScrollDirectionIsUp(event) ? scrollRight() : scrollLeft()
    }

    function checkScrollDirectionIsUp(event) {
        if (event.wheelDelta) return event.wheelDelta > 0
        return event.deltaY < 0
    }

    return (
        <>
            {graphDataAndOptions?.data && graphDataAndOptions.options && (
                <Scatter
                    ref={chartRef}
                    data={graphDataAndOptions.data}
                    options={graphDataAndOptions.options}
                    onMouseMove={(e) => drag(e)}
                    onTouchMove={(e) => drag(e)}
                    onMouseDown={(e) => handleMouseDown(e)}
                    onTouchStart={(e) => handleMouseDown(e)}
                    onMouseUp={(e) => handleMouseUp(e)}
                    onTouchEnd={(e) => handleMouseUp(e)}
                    onMouseOut={() => isDragging && setIsDragging(false)}
                />
            )}
        </>
    )
}
