import { Grid, Container, CircularProgress } from '@mui/material'
import MediaClipQuality from './MediaClipQuality'

export function QualityDiagnosableClipsSection({ isLoadingSelectedExam, isLoadingExams, examData, selectedExam, setLoadedClipsCounter }) {
    return (
        <Container sx={{ py: 2 }} maxWidth="lg">
            <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6}>
                    {isLoadingSelectedExam || isLoadingExams ? (
                        <div align="center">
                            <CircularProgress />
                        </div>
                    ) : examData !== null && examData.images !== null ? (
                        <MediaClipQuality frames={examData.images} selectedExam={selectedExam} setClipsPlayingCounter={setLoadedClipsCounter} />
                    ) : null}
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <img src="/diagnosable_quality.png" width="400px" />
                </Grid>
            </Grid>
        </Container>
    )
}
