import { useState, useEffect, useContext } from 'react'
import { Grid, Box, Container, CircularProgress } from '@mui/material'
import useFetch from '../hooks/useFetch'
import usePreLoad from '../hooks/usePreLoad'
import postDataHandler from '../handlers/postDataHandler'
import { Context } from '../App'
import MediaClipQuality from '../components/quality/MediaClipQuality'
import { viewQuestions } from '../data/cardiologists-questions'
import { QualityExamsSection } from '../components/quality/QualityExamsSection'
import { QualityQuestionsSection } from '../components/quality/QualityQuestionsSection'
import { QualityViewHeadlineSection } from '../components/quality/QualityViewHeadlineSection'

export default function LabelingCardiologistPage() {
    const AppContext = useContext(Context)
    const [sequences, setSequences] = useState(null)
    const [pageStates, setPageStates] = useState({ newExamPage: null, currentExamPage: null, totalPages: null })
    const [selectedSequence, setSelectedSequence] = useState(null)
    const [isLoadingSelectedSequence, setIsLoadingSelectedSequence] = useState(false)
    const [sequenceAnswer, setSequenceAnswer] = useState({})
    const [loadedClipsCounter, setLoadedClipsCounter] = useState(0)
    const { fetchData: fetchSequences, data: sequencesResponse, setData: setSequencesResponse, isPending: isLoadingSequences, error: sequencesFetchError } = useFetch()
    const { fetchData: fetchSequenceData, data: sequenceData, setData: setSequenceData, isPending: isLoadingSequenceData, error: sequenceDataFetchError } = useFetch()
    const numOfClips = 1
    const qualityType = 'cardiologist'

    function getSequencesPath() {
        if (pageStates.newExamPage === null) return `/api/${qualityType}/sequences`
        return `/api/${qualityType}/sequences?page=${pageStates.newExamPage}`
    }

    // fetch sequences
    useEffect(() => {
        if (!AppContext?.userEmail) {
            return
        }
        const abortController = new AbortController()
        const path = getSequencesPath()
        fetchSequences(path, abortController)
        return () => {
            abortController.abort()
        }
    }, [AppContext?.userEmail, pageStates?.newExamPage])

    // get sequences
    useEffect(() => {
        if (!sequencesResponse) return
        setSequences(sequencesResponse.sequences)
        setPageStates((prev) => ({ ...prev, currentExamPage: sequencesResponse.page, totalPages: sequencesResponse.totalPages }))
    }, [sequencesResponse])

    // get sequence data
    useEffect(() => {
        if (!selectedSequence) return
        setLoadedClipsCounter(0)
        setIsLoadingSelectedSequence(true)
        const abortController = new AbortController()
        const path = `/api/${qualityType}/exams/${selectedSequence.examId}/sequences/${selectedSequence.sequenceId}/data`
        fetchSequenceData(path, abortController)
        return () => {
            abortController.abort()
        }
    }, [selectedSequence])

    // stop loading clip
    useEffect(() => {
        sequenceData?.images && setIsLoadingSelectedSequence(false)
    }, [sequenceData])

    // use the pre-load hook to preload the next sequences in the list
    usePreLoad(loadedClipsCounter, sequences, selectedSequence, numOfClips, qualityType)

    function submitAnswer(answer) {
        const path = `/api/${qualityType}/exams/${sequenceData.examId}/sequences/${sequenceData.sequenceId}/save-data`
        async function postData() {
            const authHeaders = await AppContext?.getAuthHeaders()
            const response = await postDataHandler(path, authHeaders, answer)
            console.log(response)
            if (response.success) {
                markSequence('done')
            } else if (response?.errorMessage) {
                AppContext?.showToast(response.errorMessage)
            }
        }
        postData()
    }

    const handleSubmit = () => {
        if (questionsAnswered() && sequenceData.sequenceId !== null) {
            sequenceAnswer.view = sequenceData.view
            submitAnswer(sequenceAnswer)
        } else {
            AppContext?.showToast('please fill all the answers')
        }
    }

    const handleSkip = () => {
        const path = `/api/${qualityType}/exams/${sequenceData.examId}/sequences/${sequenceData.sequenceId}/mark-skipped`
        async function postData() {
            const authHeaders = await AppContext?.getAuthHeaders()
            const response = await postDataHandler(path, authHeaders)
            console.log(response)
            if (response.success) {
                markSequence('skipped')
            } else if (response?.errorMessage) {
                AppContext?.showToast(response.errorMessage)
            }
        }
        postData()
    }

    function selectPrevSequence() {
        const currentSequenceIndex = sequences?.indexOf(selectedSequence)
        if (currentSequenceIndex - 1 >= 0) {
            setSelectedSequence(sequences[currentSequenceIndex - 1])
        } else {
            AppContext?.showToast('No previous sequences found')
        }
    }

    function markSequence(newStatus) {
        if (sequences === null || sequences.length === 0 || selectedSequence === null) {
            return
        }
        const sequencesCopy = [...sequences]
        const sequenceIndex = sequences.indexOf(selectedSequence)
        sequencesCopy[sequenceIndex].status = newStatus
        setSequences(sequencesCopy)
    }

    function questionsAnswered() {
        if (sequences === null || sequences.length === 0 || selectedSequence === null || sequenceData == null) {
            return false
        }
        const questionValues = Object.values(viewQuestions[sequenceData.view])
        const answerKeys = Object.keys(sequenceAnswer)
        for (let i = 0; i < questionValues.length; i++) {
            if (!answerKeys.includes(questionValues[i].name)) {
                return false
            }
        }
        return true
    }

    return (
        <>
            {!sequences ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Container width="100%" sx={{ mb: '10px' }}>
                        <Grid container spacing={2}>
                            <QualityExamsSection
                                isLoadingExams={isLoadingSequences}
                                exams={sequences}
                                setSelectedExam={setSelectedSequence}
                                selectedExam={selectedSequence}
                                pageStates={pageStates}
                                setPageStates={setPageStates}
                            />
                            {/* questions sections */}
                            <QualityQuestionsSection
                                selectedExam={selectedSequence}
                                questions={viewQuestions[sequenceData?.view]}
                                setExamAnswer={setSequenceAnswer}
                                labeling={sequenceData?.labeling}
                                handleSubmit={handleSubmit}
                                selectPrevExam={selectPrevSequence}
                                handleSkip={handleSkip}
                            />
                        </Grid>
                    </Container>

                    {selectedSequence && !isLoadingSequences && !isLoadingSelectedSequence && <QualityViewHeadlineSection view={sequenceData?.view} />}
                    {/* clips section */}
                    <Container sx={{ py: 2 }} maxWidth="lg">
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6}>
                                {isLoadingSelectedSequence || isLoadingSequences ? (
                                    <div align="center">
                                        <CircularProgress />
                                    </div>
                                ) : sequenceData !== null && sequenceData.images !== null ? (
                                    <MediaClipQuality frames={sequenceData.images} selectedExam={selectedSequence} setClipsPlayingCounter={setLoadedClipsCounter} />
                                ) : null}
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <img src="/diagnosable_quality.png" width="400px" />
                            </Grid>
                        </Grid>
                    </Container>
                </>
            )}
        </>
    )
}
