import { Stack, Box, List, ListItem } from '@mui/material'
import QuestionRadio from './QuestionRadio'

export default function QuestionsList({ questions, setExamAnswer, labeling, selectedExam }) {
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', position: 'relative', overflow: 'auto', maxHeight: 600 }}>
            {questions ? (
                <nav aria-label="main mailbox folders">
                    <List>
                        {questions.map((questionObject, index) => (
                            <ListItem key={index} disablePadding>
                                <Stack direction="row" spacing={10} alignItems="center">
                                    <QuestionRadio
                                        question={questionObject.question ? questionObject.question : questionObject.displayName}
                                        questionApiName={questionObject.questionApiName ? questionObject.questionApiName : questionObject.name}
                                        answers={
                                            questionObject.answers
                                                ? questionObject.answers
                                                : [
                                                      { answer: 'Yes', value: true },
                                                      { answer: 'No', value: false }
                                                  ]
                                        }
                                        setExamAnswer={setExamAnswer}
                                        labeling={labeling}
                                        selectedExam={selectedExam}
                                    />
                                </Stack>
                            </ListItem>
                        ))}
                    </List>
                </nav>
            ) : null}
        </Box>
    )
}
