import { Grid, Box, Stack, Button } from '@mui/material'
import AllViews from '../AllViews'

export function LabelingViews({ sequenceData, setMarkReferenceView }) {
    const noViewLabels = ['Rib', 'Tissue', 'Lung', 'Detached Probe', 'Between Views', 'Unknown']

    return (
        <>
            <Grid item xs={12} sm={12} md={6}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    {sequenceData?.window === 'all' ? (
                        <AllViews setMarkReferenceView={setMarkReferenceView} />
                    ) : (
                        <>
                            <Stack direction="row" spacing={1} alignItems="center">
                                {sequenceData?.expectedLabels.map((label) => (
                                    <Button size="small" key={label} variant="contained" onClick={(e) => setMarkReferenceView(label)}>
                                        {label}
                                    </Button>
                                ))}
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                sx={{
                                    pl: '10px'
                                }}>
                                {sequenceData?.otherLabels.map((label) => (
                                    <Button
                                        size="small"
                                        key={label}
                                        variant="contained"
                                        onClick={(e) => setMarkReferenceView(label)}
                                        sx={{
                                            backgroundColor: 'darkgray'
                                        }}>
                                        {label}
                                    </Button>
                                ))}
                            </Stack>
                        </>
                    )}
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                    pt: '10px'
                }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                        {noViewLabels.map((noViewLabel) => (
                            <Button size="small" key={noViewLabel} variant="contained" onClick={(e) => setMarkReferenceView(noViewLabel)}>
                                {noViewLabel}
                            </Button>
                        ))}
                    </Stack>
                </Box>
            </Grid>
        </>
    )
}
