import { Button, Grid, Box, Container } from '@mui/material'
import { SkipPreviousRounded, DoneRounded } from '@mui/icons-material'
import QuestionsList from './QuestionsList'

export function QualityQuestionsSection({ selectedExam, questions, setExamAnswer, labeling, handleSubmit, selectPrevExam, handleSkip }) {
    return (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
                sx={{
                    pt: 2
                }}>
                <Container maxWidth="lg">
                    <QuestionsList selectedExam={selectedExam} questions={questions} setExamAnswer={setExamAnswer} labeling={labeling} />
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        size="large"
                        sx={{
                            bgcolor: '#8AE589',
                            ml: '200px',
                            '&:hover': {
                                backgroundColor: '#8AE589'
                            }
                        }}>
                        <DoneRounded />
                    </Button>
                </Container>
                <Container
                    sx={{
                        mt: '25px'
                    }}>
                    <Button onClick={selectPrevExam} variant="contained" size="small">
                        <SkipPreviousRounded />
                    </Button>
                    <Button
                        onClick={handleSkip}
                        variant="contained"
                        size="small"
                        sx={{
                            bgcolor: 'orange',
                            ml: '10px',
                            '&:hover': {
                                backgroundColor: 'orange'
                            }
                        }}>
                        skip
                    </Button>
                </Container>
            </Box>
        </Grid>
    )
}
