import Button from '@mui/material/Button'
import { SkipPreviousRounded } from '@mui/icons-material'
export function LabelingExamControlsBar({ previousSequenceButton, selectPrevSequence, handleSkip, resetSequenceButton, handleResetSequence, reviewMode, handleReviewClick }) {
    return (
        <>
            <Button ref={previousSequenceButton} onClick={selectPrevSequence} variant="contained" size="small">
                <SkipPreviousRounded />
            </Button>
            <Button
                onClick={handleSkip}
                variant="contained"
                size="small"
                sx={{
                    ml: '10px',
                    bgcolor: 'orange',
                    '&:hover': {
                        backgroundColor: 'orange'
                    }
                }}>
                skip
            </Button>
            <Button
                ref={resetSequenceButton}
                onClick={handleResetSequence}
                variant="contained"
                size="small"
                sx={{
                    ml: '10px'
                }}>
                Reset
            </Button>
            <Button
                color={reviewMode ? 'success' : 'primary'}
                onClick={handleReviewClick}
                variant="contained"
                size="small"
                sx={{
                    ml: '10px'
                }}>
                review mode
            </Button>
        </>
    )
}
