import { Typography, Button, Stack, Box } from '@mui/material'
import { NavigateNextRounded, NavigateBeforeRounded } from '@mui/icons-material'

export function PageBar({ children, pageStates, setPageStates }) {
    return (
        (pageStates.currentExamPage || pageStates.currentExamPage === 0) &&
        pageStates.totalPages && (
            <Box display="flex" justifyContent="center" alignItems="center">
                <Stack direction="row" spacing={0} alignItems="center">
                    {pageStates.currentExamPage !== 0 && (
                        <Button onClick={() => setPageStates((prev) => ({ ...prev, newExamPage: pageStates.currentExamPage - 1 }))}>
                            Previous
                            <NavigateBeforeRounded />
                        </Button>
                    )}
                    <Typography>
                        Page: {pageStates.currentExamPage + 1} / {pageStates.totalPages}
                    </Typography>
                    {pageStates.currentExamPage + 1 !== pageStates.totalPages && (
                        <Button onClick={() => setPageStates((prev) => ({ ...prev, newExamPage: pageStates.currentExamPage + 1 }))}>
                            <NavigateNextRounded />
                            Next
                        </Button>
                    )}
                    {children}
                </Stack>
            </Box>
        )
    )
}
