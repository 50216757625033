export default async function postDataHandler(apiRoute, authHeaders, body = null) {
    let errorMessage
    const url = process.env.REACT_APP_API_ENDPOINT + apiRoute
    const requestParams = authHeaders
    requestParams['method'] = 'POST'
    if (body) {
        requestParams.headers['Content-Type'] = 'application/json'
        requestParams['body'] = JSON.stringify(body)
    }
    try {
        const res = await fetch(url, requestParams)
        if (!res.ok) throw Error(`could not post data to route: ${apiRoute}`)
        const responseJson = await res.json()
        return responseJson
    } catch (err) {
        errorMessage = err.message
    }
    return { errorMessage: errorMessage }
}
