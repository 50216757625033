import React from 'react'
import { Avatar, Button, Grid, Paper, TextField, Typography, InputAdornment } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockIcon from '@mui/icons-material/Lock'

const PasswordVerificationCard = React.forwardRef(({ setVerificationCode, setNewPassword, handleVerificationCode, verificationCodeError, newPasswordError }, ref) => {
    const { newPasswordElement, verificationCodeElement } = ref.current
    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleVerificationCode()
        }
    }
    return (
        <Grid>
            <Paper
                elevation={10}
                sx={{
                    padding: 10,
                    minHeight: '200px',
                    maxHeight: '720px',
                    minWidth: '300px',
                    maxWidth: '500px',
                    margin: '5vh auto'
                }}>
                <Grid align="center">
                    <Avatar sx={{ backgroundColor: '#1B75D2', height: '70px', width: '70px', marginBottom: '20px' }}>
                        <LockOutlinedIcon fontSize="large" />
                    </Avatar>
                    <Typography variant="h6" gutterBottom>
                        Please insert your verification code
                    </Typography>
                </Grid>

                <TextField
                    ref={verificationCodeElement}
                    autoFocus
                    onChange={(e) => setVerificationCode(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{ marginTop: 5, marginBottom: 5, display: 'block' }}
                    label="Verification code"
                    placeholder="Write your verification code here"
                    type="password"
                    variant="outlined"
                    fullWidth
                    required
                    error={verificationCodeError}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon color="primary" />
                            </InputAdornment>
                        )
                    }}
                />

                <TextField
                    ref={newPasswordElement}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{ marginTop: 5, marginBottom: 5, display: 'block' }}
                    label="New Password"
                    placeholder="Write your new password here"
                    type="password"
                    variant="outlined"
                    fullWidth
                    required
                    error={newPasswordError}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon color="primary" />
                            </InputAdornment>
                        )
                    }}
                />

                <Button
                    onClick={handleVerificationCode}
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                        backgroundColor: '#1B75D2',
                        color: 'white',
                        '&:hover': { backgroundColor: '#2085ea' },
                        height: 60,
                        borderRadius: 5
                    }}>
                    Reset password
                </Button>
            </Paper>
        </Grid>
    )
})
export default PasswordVerificationCard
