import React from 'react'
import { Button, Slide, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function KeyboardShortcutsDialog() {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Button size="small" variant="outlined" onClick={handleClickOpen}>
                Keyboard Shortcuts
            </Button>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{'Keyboard Shortcuts'}</DialogTitle>
                <DialogContent>
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan="2">
                                    <b>Clip keyboard shortcuts</b>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>Spacebar</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        pause / play
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>[ or 0 or ,</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        backward frames
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>] or .</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        forward frames
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>w</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        rewind
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>c</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        jump to the end of the movie minus 2 frames
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2"></td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <b>Marking keyboard shortcuts:</b>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>r, t, l, h, e, y</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <b>R</b>ib, <b>T</b>issue, <b>L</b>ung, Detac<b>h</b>ed Probe, B<b>e</b>tween Views, Unknown
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>2, 3, 4, 5</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        2C, 3C, 4C, 5C
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>a, s, d, f, g, k</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        PLAX , SAX PAP , SAX MV, SAX AV, RPLAX, SAX APEX
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>z, x, q</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        SC 4C, SC IVC, Descending Aorta
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>u</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        undo last view mark
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2"></td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <b>Sequence keyboard shortcuts:</b>
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>o</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        reset sequence
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>n</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        next sequence
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>j</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        previous sequence
                                        <br />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>
                                        <b>b</b>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        fills sequence and marks sequence done.
                                        <br />
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
