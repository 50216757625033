import { useState, useEffect } from 'react'
import { FormLabel, FormControl, FormControlLabel, Stack, Typography, Radio, RadioGroup } from '@mui/material'

export default function QuestionRadio({ question, questionApiName, answers, setExamAnswer, labeling, selectedExam }) {
    const [option, setOption] = useState(null)

    useEffect(() => {
        if (!labeling) {
            return
        } else if (Object.keys(labeling).length === 0) {
            setOption(null)
            setExamAnswer({})
        } else {
            loadAnswer()
        }
    }, [labeling])

    // empty the radio buttons when a different exam is selected
    useEffect(() => {
        resetAnswers()
    }, [selectedExam])

    function resetAnswers() {
        setExamAnswer({})
        setOption(null)
    }

    function loadAnswer() {
        setOption(labeling[questionApiName])
        setExamAnswer((prevState) => {
            return { ...prevState, [questionApiName]: labeling[questionApiName] }
        })
    }

    return (
        <FormControl>
            <Stack direction="row" spacing={0} alignItems="center" sx={{ minWidth: 'sm' }}>
                <FormLabel sx={{ width: '230px', fontWeight: 'bold' }}>
                    <Typography variant="body" dangerouslySetInnerHTML={{ __html: question }}></Typography>
                </FormLabel>
                <RadioGroup
                    value={option}
                    onChange={(e) => {
                        setOption(e.target.value)
                    }}
                    row>
                    {answers.map((answer, index) => (
                        <FormControlLabel
                            key={index}
                            value={answer.value}
                            control={<Radio />}
                            label={<Typography sx={{ fontSize: '14px' }}>{answer.answer}</Typography>}
                            onClick={() => {
                                setExamAnswer((prevState) => {
                                    return { ...prevState, [questionApiName]: answer.value }
                                })
                            }}
                        />
                    ))}
                </RadioGroup>
            </Stack>
        </FormControl>
    )
}
