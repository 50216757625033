import React, { useState, useEffect, useRef, useContext } from 'react'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import MovieIcon from '@mui/icons-material/Movie'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import DoneIcon from '@mui/icons-material/Done'
import { Context } from '../App'

function ExamList({ exams, setSelectedExam, selectedExam, reviewMode = false }) {
    const AppContext = useContext(Context)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const examList = useRef()

    function getNextExamIndex() {
        const requiredStatus = reviewMode ? 'in_review' : 'ready'
        const currentExamIndex = exams.indexOf(selectedExam)
        if (currentExamIndex === -1) {
            return exams.findIndex((exam) => {
                return exam.status === requiredStatus
            })
        }
        // find next index in front of the list
        let index = exams.slice(currentExamIndex).findIndex((exam) => {
            return exam.status === 'ready'
        })
        if (index !== -1) {
            index += currentExamIndex
        } else {
            // if no "ready" exam is found in the front - look for one in the whole list
            index = exams.findIndex((exam) => {
                return exam.status === 'ready'
            })
        }
        return index
    }

    function handleScrollPropagation(event) {
        event.stopPropagation()
    }

    useEffect(() => {
        let localExamList = null
        if (examList.current) {
            localExamList = examList.current
            localExamList.addEventListener('wheel', handleScrollPropagation)
        }
        return function cleanup() {
            if (localExamList) localExamList.removeEventListener('wheel', handleScrollPropagation)
        }
    }, [])

    useEffect(() => {
        if (!exams || exams.length === 0) {
            return
        }
        let index = getNextExamIndex()
        // finished all exams in page
        if (index === -1) {
            AppContext?.showToast('Completed page. Please move to the next page.')
            // select the first exam on a completed page as default
            index = 0
        }
        setSelectedExam(exams[index])
        setSelectedIndex(index)
        handleScroll(index)
    }, [exams])

    useEffect(() => {
        if (!exams || !selectedExam) {
            return
        }
        const index = exams.indexOf(selectedExam)
        if (index === -1) {
            return
        }
        if (index !== selectedIndex) {
            setSelectedIndex(index)
            handleScroll(index)
        }
    }, [selectedExam])

    function handleScroll(index) {
        let selected
        if (index < 3) {
            selected = examList.current.children[0]
            selected.scrollIntoView()
            return
        }
        selected = examList.current.children[index - 2]
        selected.scrollIntoView()
    }

    const handleListItemClick = (event, index, item) => {
        setSelectedIndex(index)
        setSelectedExam(item)
    }

    return (
        <Box>
            <nav aria-label="main mailbox folders">
                <List ref={examList}>
                    {exams.map((item, index) =>
                        item?.status === 'done' ? (
                            <ListItem key={index} disablePadding divider={true} sx={{ bgcolor: '#ccff90', height: '40px' }}>
                                <ListItemButton
                                    selected={selectedIndex === index}
                                    onClick={(event) => handleListItemClick(event, index, item)}
                                    sx={{
                                        '&.Mui-selected': {
                                            backgroundColor: '#81d4fa',
                                            ':hover': { backgroundColor: '#44a2ea' },
                                            height: '40px'
                                        }
                                    }}>
                                    <ListItemIcon>
                                        <DoneIcon color="black" />
                                    </ListItemIcon>
                                    <ListItemText primary={item?.name} primaryTypographyProps={{ fontSize: '14px' }} />
                                </ListItemButton>
                            </ListItem>
                        ) : item?.status === 'skipped' ? (
                            <ListItem key={index} disablePadding divider={true} sx={{ bgcolor: '#ffff8d', height: '40px' }}>
                                <ListItemButton
                                    selected={selectedIndex === index}
                                    onClick={(event) => handleListItemClick(event, index, item)}
                                    sx={{
                                        '&.Mui-selected': {
                                            backgroundColor: '#81d4fa',
                                            ':hover': { backgroundColor: '#44a2ea' },
                                            height: '40px'
                                        }
                                    }}>
                                    <ListItemIcon>
                                        <QuestionMarkIcon color="black" />
                                    </ListItemIcon>
                                    <ListItemText primary={item?.name} primaryTypographyProps={{ fontSize: '14px' }} />
                                </ListItemButton>
                            </ListItem>
                        ) : (
                            <ListItem key={index} disablePadding divider={true} sx={{ height: '40px' }}>
                                <ListItemButton
                                    selected={selectedIndex === index}
                                    onClick={(event) => handleListItemClick(event, index, item)}
                                    sx={{
                                        '&.Mui-selected': {
                                            backgroundColor: '#81d4fa',
                                            ':hover': { backgroundColor: '#44a2ea' },
                                            height: '40px'
                                        }
                                    }}>
                                    <ListItemIcon>
                                        <MovieIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={item?.name} primaryTypographyProps={{ fontSize: '14px' }} />
                                </ListItemButton>
                            </ListItem>
                        )
                    )}
                </List>
            </nav>
        </Box>
    )
}
export default React.memo(ExamList)
