import React, { useState, useEffect, useRef } from 'react'
import { Typography, Card } from '@mui/material'
import QualityCardActions from './QualityCardActions'
import CircularProgressWithLabel from '../CircularProgressWithLabel'

function MediaClipQuality({ screenLocation, frames, selectedExam, setClipsPlayingCounter }) {
    const [imageSrcArray, setImageSrcArray] = useState([]) // video images

    const [loadedFramesCounter, setLoadedFramesCounter] = useState(0) // amount of loaded frames

    const [currentFrameMs, setCurrentFrameMs] = useState(0) // current presented frame number

    const [clipInterval, setClipInterval] = useState(null)
    const cardElement = useRef()
    const imageElement = useRef()
    const loadElement = useRef()

    // reset the states
    useEffect(() => {
        setImageSrcArray([])
        setLoadedFramesCounter(0)
        setCurrentFrameMs(0)
        loadElement.current.replaceChildren()
        resetClip()
    }, [selectedExam])

    // set the frames counter
    useEffect(() => {
        if (frames === null || frames.length === 0) {
            return
        }
        loadImages()
    }, [frames])

    // set the images array
    async function loadImages() {
        if (frames !== null && frames.length > 0) {
            frames.forEach((frame) => {
                const image = document.createElement('img')
                image.setAttribute('src', frame.frame_filename)
                image.addEventListener('load', () => {
                    setLoadedFramesCounter((preLoadedFramesCounter) => preLoadedFramesCounter + 1)
                })
                loadElement.current.appendChild(image)
                setImageSrcArray((imageArray) => [...imageArray, frame.frame_filename])
            })
        }
    }

    // the frames are successfully loaded
    useEffect(() => {
        if (frames.length === 0 || loadedFramesCounter === 0) {
            return
        }
        if (loadedFramesCounter === frames.length) {
            // enable pre-loading
            setClipsPlayingCounter((clipsPlayingCounter) => clipsPlayingCounter + 1)
            // play clip
            playClip()
            cardElement.current.style.display = 'flex'
        }
    }, [loadedFramesCounter, frames])

    async function playClip() {
        let i = currentFrameMs
        const loop = setInterval(() => {
            if (i === frames.length) {
                i = 0
            }
            changeImage(i)
            i += 1
        }, 35)
        setClipInterval(loop)
    }

    async function changeImage(index) {
        if (imageElement.current) {
            imageElement.current.src = imageSrcArray[index]
            setCurrentFrameMs(index)
        }
    }

    async function resetClip() {
        if (clipInterval !== null) {
            stopInterval()
            cardElement.current.style.display = 'none'
        }
    }

    async function stopInterval() {
        clearInterval(clipInterval)
        setClipInterval(null)
    }

    return (
        <div>
            {loadedFramesCounter !== 0 && loadedFramesCounter !== frames.length && (
                <div align="center">
                    <CircularProgressWithLabel value={(loadedFramesCounter / frames.length) * 100} />
                </div>
            )}
            <div>
                <Card ref={cardElement} style={{ display: 'none' }} sx={{ height: '100%', display: 'flex', flexDirection: 'column', minWidth: '400px' }}>
                    {screenLocation && <Typography variant="h6" align="center" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{`${screenLocation} clip`}</Typography>}
                    <img ref={imageElement} src="#" />
                    <QualityCardActions playFunction={playClip} pauseFunction={stopInterval} currentFrame={currentFrameMs} framesCount={frames.length} />
                </Card>
            </div>
            <div ref={loadElement} style={{ visibility: 'hidden', width: '0px', height: '0px', overflow: 'hidden' }} />
        </div>
    )
}
export default React.memo(MediaClipQuality)
