import { Box, IconButton, Link, AppBar, Toolbar } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'

export default function UltraSightAppBar() {
    return (
        <div>
            <Box style={{display: 'none'}} sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <Link href="/" color="inherit" variant="h6" noWrap underline="none" flexGrow="1">
                            UltraSight Labeling
                        </Link>
                        <Link href="/" color="inherit" sx={{ mr: 1 }} noWrap>
                            <IconButton color="inherit">
                                <HomeIcon fontSize="large" />
                            </IconButton>
                        </Link>
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    )
}
