export const viewQuestions = {
    PLAX: [
        {
            name: 'mv_av_center',
            displayName: 'MV/AV in center of clip (+/- 25%)'
        },
        {
            name: 'rv',
            displayName: 'RV observed'
        },
        {
            name: 'la',
            displayName: 'LA observed'
        },
        {
            name: 'distal_lv_not',
            displayName: 'Distal LV <u>is NOT</u> observed'
        },
        {
            name: 'clipQualityPLAX',
            displayName: 'Grade the clip quality',
            answers: [
                { answer: '1', value: 1 },
                { answer: '2', value: 2 },
                { answer: '3', value: 3 },
                { answer: '4', value: 4 },
                { answer: '5', value: 5 }
            ]
        }
    ],
    SAX_AV: [
        {
            name: 'av_cusps',
            displayName: 'AV cusps observed'
        },
        {
            name: 'rv_outflow',
            displayName: 'RV outflow tract observed'
        },
        {
            name: 'clipQualityAV',
            displayName: 'Grade the clip quality',
            answers: [
                { answer: '1', value: 1 },
                { answer: '2', value: 2 },
                { answer: '3', value: 3 },
                { answer: '4', value: 4 },
                { answer: '5', value: 5 }
            ]
        }
    ],
    SAX_MV: [
        {
            name: 'mv_fish',
            displayName: 'MV Fish mouth seen'
        },
        {
            name: 'lv_round_rv_crescent',
            displayName: 'LV appear round in shape and the RV crescent shaped'
        },
        {
            name: 'clipQualityMV',
            displayName: 'Grade the clip quality',
            answers: [
                { answer: '1', value: 1 },
                { answer: '2', value: 2 },
                { answer: '3', value: 3 },
                { answer: '4', value: 4 },
                { answer: '5', value: 5 }
            ]
        }
    ],
    SAX_PAP: [
        {
            name: 'lv_center',
            displayName: 'LV in the center of the image'
        },
        {
            name: 'pm',
            displayName: 'PM seen'
        },
        {
            name: 'clipQualityPAP',
            displayName: 'Grade the clip quality',
            answers: [
                { answer: '1', value: 1 },
                { answer: '2', value: 2 },
                { answer: '3', value: 3 },
                { answer: '4', value: 4 },
                { answer: '5', value: 5 }
            ]
        }
    ],
    '4C': [
        {
            name: 'fourC',
            displayName: '4C?'
        },
        {
            name: 'rvSize',
            displayName: 'RV size'
        },
        {
            name: 'rvFunc',
            displayName: 'RV function'
        },
        {
            name: 'la',
            displayName: 'LA size'
        },
        {
            name: 'foreshortened4C',
            displayName: 'View <u>is NOT</u> foreshortened'
        },
        {
            name: 'clipQuality4C',
            displayName: 'Grade the clip quality',
            answers: [
                { answer: '1', value: 1 },
                { answer: '2', value: 2 },
                { answer: '3', value: 3 },
                { answer: '4', value: 4 },
                { answer: '5', value: 5 }
            ]
        }
    ],
    '5C': [
        {
            name: 'lvot',
            displayName: 'Can you measure LVOT gradient in this view'
        },
        {
            name: 'clipQuality5C',
            displayName: 'Grade the clip quality',
            answers: [
                { answer: '1', value: 1 },
                { answer: '2', value: 2 },
                { answer: '3', value: 3 },
                { answer: '4', value: 4 },
                { answer: '5', value: 5 }
            ]
        }
    ],
    '2C': [
        {
            name: 'anterior',
            displayName: 'Anterior wall seen'
        },
        {
            name: 'posterior',
            displayName: 'Posterior wall seen'
        },
        {
            name: 'la',
            displayName: 'LA observed'
        },
        {
            name: 'rv_not',
            displayName: 'RV is “closed”'
        },
        {
            name: 'foreshortened2C',
            displayName: 'View <u>is NOT</u> foreshortened'
        },
        {
            name: 'clipQuality2C',
            displayName: 'Grade the clip quality',
            answers: [
                { answer: '1', value: 1 },
                { answer: '2', value: 2 },
                { answer: '3', value: 3 },
                { answer: '4', value: 4 },
                { answer: '5', value: 5 }
            ]
        }
    ],
    '3C': [
        {
            name: 'lvot',
            displayName: 'LVOT observed'
        },
        {
            name: 'septum_vertical',
            displayName: 'Septum is vertical +/- 25°'
        },
        {
            name: 'clipQuality3C',
            displayName: 'Grade the clip quality',
            answers: [
                { answer: '1', value: 1 },
                { answer: '2', value: 2 },
                { answer: '3', value: 3 },
                { answer: '4', value: 4 },
                { answer: '5', value: 5 }
            ]
        }
    ],
    SC_4C: [
        {
            name: 'lv',
            displayName: 'LV observed'
        },
        {
            name: 'rv',
            displayName: 'RV observed'
        },
        {
            name: 'clipQualitySC4C',
            displayName: 'Grade the clip quality',
            answers: [
                { answer: '1', value: 1 },
                { answer: '2', value: 2 },
                { answer: '3', value: 3 },
                { answer: '4', value: 4 },
                { answer: '5', value: 5 }
            ]
        }
    ],
    SC_IVC: [
        {
            name: 'ra_ivc_junction',
            displayName: 'RA-IVC junction observed'
        },
        {
            name: 'clipQualityIVC',
            displayName: 'Grade the clip quality',
            answers: [
                { answer: '1', value: 1 },
                { answer: '2', value: 2 },
                { answer: '3', value: 3 },
                { answer: '4', value: 4 },
                { answer: '5', value: 5 }
            ]
        }
    ]
}
