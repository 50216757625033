import { Avatar, Button, Grid, Paper, TextField, Typography, InputAdornment } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'

export default function LoginCard({ setUsername, setPassword, setShowForgotPassword, handleSubmit, usernameError, passwordError }) {
    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    return (
        <Grid>
            <Paper
                elevation={10}
                sx={{
                    padding: 10,
                    minHeight: '200px',
                    maxHeight: '720px',
                    minWidth: '300px',
                    maxWidth: '500px',
                    margin: '5vh auto'
                }}>
                <Grid align="center">
                    <Avatar sx={{ backgroundColor: '#1B75D2', height: '70px', width: '70px', marginBottom: '20px' }}>
                        <LockOutlinedIcon fontSize="large" />
                    </Avatar>
                    <Typography variant="h4" component="h2" gutterBottom>
                        Login
                    </Typography>
                </Grid>

                <TextField
                    autoFocus
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{ marginTop: 5, marginBottom: 5, display: 'block' }}
                    label="Username"
                    placeholder="Write your username here"
                    variant="outlined"
                    fullWidth
                    required
                    error={usernameError}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon color="primary" />
                            </InputAdornment>
                        )
                    }}
                />

                <TextField
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{ marginTop: 5, display: 'block' }}
                    label="Password"
                    placeholder="Write your password here"
                    type="password"
                    variant="outlined"
                    fullWidth
                    required
                    error={passwordError}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon color="primary" />
                            </InputAdornment>
                        )
                    }}
                />

                <Button onClick={() => setShowForgotPassword(true)} sx={{ mb: 5, display: 'block' }}>
                    Forgot password?
                </Button>

                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                        backgroundColor: '#1B75D2',
                        color: 'white',
                        '&:hover': { backgroundColor: '#2085ea' },
                        height: 60,
                        borderRadius: 5
                    }}>
                    Login
                </Button>
            </Paper>
        </Grid>
    )
}
