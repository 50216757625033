import { Grid, Container, CircularProgress } from '@mui/material'
import MediaClipQuality from './MediaClipQuality'

export function QualityCompareClipsSection({ isLoadingSelectedExam, isLoadingExams, pairData, selectedExam, setLoadedClipsCounter }) {
    return (
        <Container
            sx={{
                py: 2
            }}
            maxWidth="lg">
            <Grid container spacing={4}>
                <Grid item xs={6} sm={6} md={6}>
                    {isLoadingSelectedExam || isLoadingExams ? (
                        <div align="center">
                            <CircularProgress />
                        </div>
                    ) : pairData !== null && pairData.images.clip1 !== null ? (
                        <MediaClipQuality screenLocation="left" frames={pairData.images.clip1} selectedExam={selectedExam} setClipsPlayingCounter={setLoadedClipsCounter} />
                    ) : null}
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    {isLoadingSelectedExam || isLoadingExams ? (
                        <div align="center">
                            <CircularProgress />
                        </div>
                    ) : pairData !== null && pairData.images.clip2 !== null ? (
                        <MediaClipQuality screenLocation="right" frames={pairData.images.clip2} selectedExam={selectedExam} setClipsPlayingCounter={setLoadedClipsCounter} />
                    ) : null}
                </Grid>
            </Grid>
        </Container>
    )
}
