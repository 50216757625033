import { useContext, useState } from 'react'
import { Context } from '../App'

const useFetch = () => {
    const AppContext = useContext(Context)
    const [data, setData] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState(null)

    async function fetchData(apiRoute, abortController) {
        const url = process.env.REACT_APP_API_ENDPOINT + apiRoute
        setIsPending(true)
        const authHeaders = await AppContext?.getAuthHeaders()
        const params = { ...authHeaders, signal: abortController.signal }
        try {
            const res = await fetch(url, params)
            if (!res.ok) throw Error(`could not fetch the data for that resource on route: ${apiRoute}`)
            const newData = await res.json()
            setData(newData)
            console.log('data fetched: ', newData)
            setError(null)
        } catch (err) {
            if (err.name === 'AbortError') console.log('fetch aborted')
            else setError(err.message)
        } finally {
            setIsPending(false)
        }
    }

    return { fetchData, data, setData, isPending, error }
}

export default useFetch
