import { LabelingViews } from './../components/labeling/LabelingViews'
import { LabelingExamControlsBar } from './../components/labeling/LabelingExamControlsBar'
import { useState, useEffect, useRef, useContext } from 'react'
import { Typography, Button, Grid, Box, Container, CircularProgress } from '@mui/material'
import { NavigateNextRounded } from '@mui/icons-material'
import useFetch from '../hooks/useFetch'
import usePreLoad from '../hooks/usePreLoad'
import useLabelingKeyboardShortcuts from '../hooks/useLabelingKeyboardShortcuts'
import postDataHandler from '../handlers/postDataHandler'
import { Context } from '../App'
import ExamList from '../components/ExamList'
import { PageBar } from '../components/PageBar'
import LabelingGraph from '../components/labeling/LabelingGraph'
import MediaClipLabeling from '../components/labeling/MediaClipLabeling'
import KeyboardShortcutsDialog from '../components/labeling/LabelingKeyboardShortcutsDialog'

export default function Labeling() {
    const AppContext = useContext(Context)
    const [reviewMode, setReviewMode] = useState(false)
    const [sequences, setSequences] = useState(null)
    const [pageStates, setPageStates] = useState({ newExamPage: null, currentExamPage: null, nextReadyPage: -1, totalPages: null })
    const [selectedSequence, setSelectedSequence] = useState(null)
    const [isLoadingSelectedSequence, setIsLoadingSelectedSequence] = useState(false)
    const [markReferenceView, setMarkReferenceView] = useState(null)
    const [currentFrame, setCurrentFrame] = useState(0) // current presented frame number
    const [showDone, setShowDone] = useState(false)
    const [markingError, setMarkingError] = useState(null)
    const [loadedClipsCounter, setLoadedClipsCounter] = useState(0)
    const [clipSpeed, setClipSpeed] = useState(1)
    const [isClipPlaying, setIsClipPlaying] = useState(false)
    const { fetchData: fetchSequences, data: sequencesResponse, setData: setSequencesResponse, isPending: isLoadingSequences, error: sequencesFetchError } = useFetch()
    const { fetchData: fetchSequenceData, data: sequenceData, setData: setSequenceData, isPending: isLoadingSequenceData, error: sequenceDataFetchError } = useFetch()

    const resetSequenceButton = useRef()
    const previousSequenceButton = useRef()
    const doneButton = useRef()
    const undoButton = useRef()
    const numOfClips = 1
    const labelingType = 'sonographer'

    function getSequencesPath() {
        const path = `/api/sonographer/sequences`
        const newPage = `page=${pageStates.newExamPage}`
        const review = `review=true`
        if (pageStates.newExamPage === null && reviewMode) return `${path}?${review}`
        else if (pageStates.newExamPage !== null && !reviewMode) return `${path}?${newPage}`
        else if (pageStates.newExamPage !== null && reviewMode) return `${path}?${newPage}&${review}`
        else return path
    }

    // fetch sequences
    useEffect(() => {
        console.log(AppContext?.userEmail)
        if (!AppContext?.userEmail) {
            return
        }
        const abortController = new AbortController()
        const path = getSequencesPath()
        fetchSequences(path, abortController)
        return () => {
            abortController.abort()
        }
    }, [AppContext?.userEmail, pageStates?.newExamPage, reviewMode])

    // get sequences
    useEffect(() => {
        if (!sequencesResponse) return
        setSequences(sequencesResponse.sequences)
        setPageStates((prev) => ({ ...prev, currentExamPage: sequencesResponse.page, nextReadyPage: sequencesResponse.nextReadyPage, totalPages: sequencesResponse.totalPages }))
    }, [sequencesResponse])

    // fetch sequence data
    useEffect(() => {
        if (!selectedSequence) return
        setShowDone(false)
        setMarkingError(null)
        setLoadedClipsCounter(0)
        setIsLoadingSelectedSequence(true)
        const abortController = new AbortController()
        const path = `/api/sonographer/exams/${selectedSequence.examId}/sequences/${selectedSequence.sequenceId}/data`
        fetchSequenceData(path, abortController)
        return () => {
            abortController.abort()
        }
    }, [selectedSequence])

    // sequence data changed
    useEffect(() => {
        sequenceData?.images && setIsLoadingSelectedSequence(false)
    }, [sequenceData])

    // use page keyboard shortcuts hook. controls all except clip.
    useLabelingKeyboardShortcuts(setMarkReferenceView, undoButton, resetSequenceButton, previousSequenceButton, doneButton)

    // use the pre-load hook to preload the next sequences in the list
    usePreLoad(loadedClipsCounter, sequences, selectedSequence, numOfClips, labelingType)

    // post a new view start
    useEffect(() => {
        if (!markReferenceView || !sequenceData?.images) return
        if (sequenceData.images[currentFrame].ms === null || sequenceData.images[currentFrame].ms === undefined) return
        const path = `/api/sonographer/exams/${sequenceData.examId}/sequences/${sequenceData.sequenceId}/mark-view-start`
        const data = { view: markReferenceView, frame_timestamp: sequenceData.images[currentFrame].ms }
        async function postData() {
            const authHeaders = await AppContext?.getAuthHeaders()
            const response = await postDataHandler(path, authHeaders, data)
            console.log(response)
            setMarkReferenceView(null)
            if (response?.success) {
                setSequenceData((prev) => ({ ...prev, firstGraph: response.firstGraph, labeling: response.labeling }))
                setIsClipPlaying(true)
                setShowDone(false)
                setMarkingError(null)
            } else if (response?.errorMessage) {
                AppContext?.showToast(response.errorMessage)
            }
        }
        postData()
    }, [markReferenceView])

    function handleUndoOrResetPostSuccess(response) {
        markSequence('ready')
        setShowDone(false)
        setMarkingError(null)
        if (!response.firstGraph) return
        setSequenceData((prev) => ({ ...prev, firstGraph: response.firstGraph, labeling: response.labeling }))
    }

    function handleUndoLastMarkClick() {
        const path = `/api/sonographer/exams/${sequenceData.examId}/sequences/${sequenceData.sequenceId}/undo-last-mark`
        async function postData() {
            const authHeaders = await AppContext?.getAuthHeaders()
            const response = await postDataHandler(path, authHeaders)
            setMarkReferenceView(null)
            console.log(response)
            if (response.success) {
                handleUndoOrResetPostSuccess(response)
            } else if (response?.errorMessage) {
                AppContext?.showToast(response.errorMessage)
            }
        }
        postData()
    }

    function handleResetSequence() {
        const path = `/api/sonographer/exams/${sequenceData.examId}/sequences/${sequenceData.sequenceId}/reset-sequence`
        async function postData() {
            const authHeaders = await AppContext?.getAuthHeaders()
            const response = await postDataHandler(path, authHeaders)
            setMarkReferenceView(null)
            console.log(response)
            if (response.success) {
                handleUndoOrResetPostSuccess(response)
            } else if (response?.errorMessage) {
                AppContext?.showToast(response.errorMessage)
            }
        }
        postData()
    }

    const handleSkip = () => {
        let path = checkExamDone()
            ? `/api/sonographer/exams/${sequenceData.examId}/sequences/${sequenceData.sequenceId}/mark-skipped?also_exam=${true}`
            : `/api/sonographer/exams/${sequenceData.examId}/sequences/${sequenceData.sequenceId}/mark-skipped`
        async function postData() {
            const authHeaders = await AppContext?.getAuthHeaders()
            const response = await postDataHandler(path, authHeaders)
            setMarkReferenceView(null)
            console.log(response)
            if (response.success) {
                markSequence('skipped')
            } else if (response?.errorMessage) {
                AppContext?.showToast(response.errorMessage)
            }
        }
        postData()
    }

    // shows the done button only when the user reached the last frame in the sequence and there is at least on mark
    useEffect(() => {
        if (currentFrame !== sequenceData?.images.length - 1) return
        let firstFrame = sequenceData?.images[0]['ms'].toString()
        if (sequenceData?.labeling && Object.keys(sequenceData?.labeling).includes(firstFrame)) setShowDone(true)
        else setMarkingError('First frame is not marked. Please mark at least the first frame.')
    }, [currentFrame, sequenceData?.images, sequenceData?.labeling])

    function checkExamDone() {
        if (!sequences) return
        const sequencesToCheck = [...sequences]
        const index = sequencesToCheck.indexOf(selectedSequence)
        sequencesToCheck.splice(index, 1)
        for (const sequence of sequencesToCheck) {
            if (sequence.status !== 'done') return false
        }
        return true
    }

    function handleDone() {
        let path = checkExamDone()
            ? `/api/sonographer/exams/${sequenceData.examId}/sequences/${sequenceData.sequenceId}/mark-done?also_exam=${true}`
            : `/api/sonographer/exams/${sequenceData.examId}/sequences/${sequenceData.sequenceId}/mark-done`
        async function postData() {
            const authHeaders = await AppContext?.getAuthHeaders()
            const response = await postDataHandler(path, authHeaders)
            console.log(response)
            if (response.success) {
                setMarkReferenceView(null)
                markSequence('done')
            } else if (response?.errorMessage) {
                AppContext?.showToast(response.errorMessage)
            } else {
                AppContext?.showToast("sequence can't be marked as done")
            }
        }
        postData()
    }

    function markSequence(newStatus) {
        if (sequences === null || sequences.length === 0 || selectedSequence === null) {
            return
        }
        const sequencesCopy = [...sequences]
        const sequenceIndex = sequences.indexOf(selectedSequence)
        sequencesCopy[sequenceIndex].status = newStatus
        setSequences(sequencesCopy)
    }

    function selectPrevSequence() {
        const currentSequenceIndex = sequences?.indexOf(selectedSequence)
        if (currentSequenceIndex - 1 >= 0) {
            setSelectedSequence(sequences[currentSequenceIndex - 1])
        } else {
            AppContext?.showToast('No previous sequences found')
        }
    }

    function handleReviewClick() {
        setSequences(null)
        setSelectedSequence(null)
        setReviewMode(!reviewMode)
    }

    function handleNextReadyPage() {
        if (pageStates?.nextReadyPage > 0) setPageStates((prev) => ({ ...prev, newExamPage: pageStates.nextReadyPage }))
        else AppContext?.showToast("Couldn't find next ready page")
    }

    return (
        <>
            {!sequences ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Container width="100%" sx={{ mb: '10px' }}>
                        <Grid container spacing={2}>
                            {/* exam list section */}
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <Box sx={{ pt: 2 }}>
                                    <Container maxWidth="lg">
                                        {isLoadingSequences ? (
                                            <Box height="210px" display="flex" justifyContent="center" alignItems="center">
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            <Box sx={{ width: '100%', bgcolor: 'background.paper', position: 'relative', overflow: 'auto', maxHeight: '288px', maxWidth: '480px' }}>
                                                <ExamList exams={sequences} setSelectedExam={setSelectedSequence} selectedExam={selectedSequence} reviewMode={reviewMode} />
                                            </Box>
                                        )}
                                        <PageBar pageStates={pageStates} setPageStates={setPageStates}>
                                            {pageStates.currentExamPage + 1 !== pageStates.totalPages && (
                                                <Button onClick={handleNextReadyPage}>
                                                    <NavigateNextRounded />
                                                    Next Ready
                                                </Button>
                                            )}
                                        </PageBar>
                                        <LabelingExamControlsBar
                                            previousSequenceButton={previousSequenceButton}
                                            selectPrevSequence={selectPrevSequence}
                                            handleSkip={handleSkip}
                                            resetSequenceButton={resetSequenceButton}
                                            handleResetSequence={handleResetSequence}
                                            reviewMode={reviewMode}
                                            handleReviewClick={handleReviewClick}
                                        />
                                    </Container>
                                </Box>
                            </Grid>

                            {/* clip section */}
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <Box sx={{ pt: 2 }}>
                                    {isLoadingSelectedSequence || isLoadingSequences ? (
                                        <div align="center">
                                            <CircularProgress />
                                        </div>
                                    ) : sequenceData !== null && sequenceData.images !== null ? (
                                        <div>
                                            <MediaClipLabeling
                                                currentFrame={currentFrame}
                                                setCurrentFrame={setCurrentFrame}
                                                frames={sequenceData.images}
                                                selectedExam={selectedSequence}
                                                setClipsPlayingCounter={setLoadedClipsCounter}
                                                isClipPlaying={isClipPlaying}
                                                setIsClipPlaying={setIsClipPlaying}
                                                clipSpeed={clipSpeed}
                                                setClipSpeed={setClipSpeed}
                                            />
                                            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" maxWidth="200px" sx={{ pt: 0.5 }}>
                                                <Typography variant="h6">{sequenceData.adminComment}</Typography>
                                            </Box>
                                            <Box position="fixed" maxWidth="580px" sx={{ pt: 0.5, ml: '410px' }}>
                                                <KeyboardShortcutsDialog />
                                            </Box>
                                        </div>
                                    ) : null}
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>

                    {/* views section */}
                    <Container style={{ width: '800px' }}>
                        <LabelingViews sequenceData={sequenceData} setMarkReferenceView={setMarkReferenceView} />
                        <br />

                        {/* graph section */}
                        {isLoadingSelectedSequence || isLoadingSequences || loadedClipsCounter < numOfClips ? (
                            <div align="center">
                                <CircularProgress />
                            </div>
                        ) : sequenceData !== null && sequenceData?.firstGraph !== null && loadedClipsCounter === numOfClips ? (
                            <>
                                <Box display="flex">
                                    <Button
                                        ref={undoButton}
                                        onClick={handleUndoLastMarkClick}
                                        variant="outlined"
                                        style={{ maxHeight: '30px', minHeight: '30px', alignSelf: 'center', color: 'black', borderColor: 'black' }}>
                                        Undo
                                    </Button>
                                    {markingError && (
                                        <Typography sx={{ ml: '10px' }} color="error.main">
                                            {markingError}
                                        </Typography>
                                    )}
                                </Box>
                                <Box style={{ height: '140px', display: 'inline-flex', width: '800px' }}>
                                    <LabelingGraph sequenceData={sequenceData} currentFrame={currentFrame} setCurrentFrame={setCurrentFrame} setIsClipPlaying={setIsClipPlaying} />
                                    {showDone && (
                                        <Button ref={doneButton} onClick={handleDone} variant="contained" style={{ height: '30px', alignSelf: 'center' }}>
                                            done
                                        </Button>
                                    )}
                                </Box>
                            </>
                        ) : null}
                    </Container>
                </>
            )}
        </>
    )
}
