import { QualityViewHeadlineSection } from '../components/quality/QualityViewHeadlineSection'
import { useState, useEffect, useContext } from 'react'
import { Grid, Box, Container, CircularProgress } from '@mui/material'
import usePreLoad from '../hooks/usePreLoad'
import useFetch from '../hooks/useFetch'
import postDataHandler from '../handlers/postDataHandler'
import { Context } from '../App'
import { diagnosableQuestions } from '../data/diagnosable-questions'
import { compareQuestions } from '../data/compare-questions'
import { QualityExamsSection } from '../components/quality/QualityExamsSection'
import { QualityQuestionsSection } from '../components/quality/QualityQuestionsSection'
import { QualityDiagnosableClipsSection } from '../components/quality/QualityDiagnosableClipsSection'
import { QualityCompareClipsSection } from '../components/quality/QualityCompareClipsSection'

export default function QualityPage({ qualityType }) {
    const AppContext = useContext(Context)
    const [exams, setExams] = useState(null)
    const [pageStates, setPageStates] = useState({ newExamPage: null, currentExamPage: null, totalPages: null })
    const [selectedExam, setSelectedExam] = useState(null)
    const [isLoadingSelectedExam, setIsLoadingSelectedExam] = useState(false)
    const [examAnswer, setExamAnswer] = useState({})
    const [loadedClipsCounter, setLoadedClipsCounter] = useState(0)
    const { fetchData: fetchExams, data: examsResponse, setData: setExamsResponse, isPending: isLoadingExams, error: examsFetchError } = useFetch()
    const { fetchData: fetchExamData, data: examData, setData: setExamData, isPending: isLoadingExamData, error: examDataFetchError } = useFetch()
    const numOfClips = qualityType === 'compare' ? 2 : 1

    function getExamsPath() {
        if (pageStates.newExamPage === null) return `/api/quality/${qualityType}/exams`
        return `/api/quality/${qualityType}/exams?page=${pageStates.newExamPage}`
    }

    // fetch exams
    useEffect(() => {
        if (!AppContext?.userEmail) return
        const abortController = new AbortController()
        const path = getExamsPath()
        fetchExams(path, abortController)
        return () => {
            abortController.abort()
        }
    }, [AppContext?.userEmail, pageStates?.newExamPage])

    useEffect(() => {
        if (!examsResponse) return
        setExams(examsResponse.exams)
        setPageStates((prev) => ({ ...prev, currentExamPage: examsResponse.page, totalPages: examsResponse.totalPages }))
    }, [examsResponse])

    // get exam data
    useEffect(() => {
        if (!selectedExam) return
        setLoadedClipsCounter(0)
        setIsLoadingSelectedExam(true)
        const abortController = new AbortController()
        const path = `/api/quality/${qualityType}/exams/${selectedExam.id}/data`
        fetchExamData(path, abortController)
        return () => {
            abortController.abort()
        }
    }, [selectedExam])

    // stop loading clip
    useEffect(() => {
        if (qualityType === 'compare') examData?.images?.clip1 && setIsLoadingSelectedExam(false)
        else examData?.images && setIsLoadingSelectedExam(false)
    }, [examData])

    // use the pre-load hook to preload the next exams in the list
    usePreLoad(loadedClipsCounter, exams, selectedExam, numOfClips, qualityType)

    function submitAnswer(answer) {
        const path = `/api/quality/${qualityType}/exams/${examData.examId}/save-data`
        async function postData() {
            const authHeaders = await AppContext?.getAuthHeaders()
            const response = await postDataHandler(path, authHeaders, answer)
            console.log(response)
            if (response.success) {
                markExam('done')
            } else if (response?.errorMessage) {
                AppContext?.showToast(response.errorMessage)
            }
        }
        postData()
    }

    const handleSubmit = () => {
        if (questionsAnswered() && examData.examId !== null) {
            const finalAnswer = createResponseJson(selectedExam.view, selectedExam.name, examAnswer)
            submitAnswer(finalAnswer)
        } else {
            AppContext?.showToast('please fill all the answers')
        }
    }

    const handleSkip = () => {
        const path = `/api/quality/${qualityType}/exams/${examData.examId}/mark-skipped`
        async function postData() {
            const authHeaders = await AppContext?.getAuthHeaders()
            const response = await postDataHandler(path, authHeaders)
            console.log(response)
            if (response.success) markExam('skipped')
        }
        postData()
    }

    function selectPrevExam() {
        const currentExamIndex = exams?.indexOf(selectedExam)
        if (currentExamIndex - 1 >= 0) setSelectedExam(exams[currentExamIndex - 1])
        else AppContext?.showToast('No previous exams found')
    }

    function markExam(newStatus) {
        if (!exams || exams.length === 0 || !selectedExam) return
        const examsCopy = [...exams]
        const examIndex = exams.indexOf(selectedExam)
        examsCopy[examIndex].status = newStatus
        setExams(examsCopy)
    }

    const createResponseJson = (view, name, examAnswerObject) => {
        if (qualityType === 'compare') return { view: view, name: name, whos_better: examAnswerObject.whos_better }
        return { view: view, name: name, clip_diagnosable: examAnswerObject.clip_diagnosable }
    }

    function questionsAnswered() {
        if (qualityType === 'compare') return examAnswer?.whos_better !== undefined
        return examAnswer?.clip_diagnosable !== undefined
    }

    return (
        <>
            {!exams ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Container width="100%" sx={{ mb: '10px' }}>
                        <Grid container spacing={2}>
                            <QualityExamsSection
                                isLoadingExams={isLoadingExams}
                                exams={exams}
                                setSelectedExam={setSelectedExam}
                                selectedExam={selectedExam}
                                pageStates={pageStates}
                                setPageStates={setPageStates}
                            />
                            {/* questions sections */}
                            <QualityQuestionsSection
                                selectedExam={selectedExam}
                                questions={qualityType === 'compare' ? compareQuestions : diagnosableQuestions}
                                setExamAnswer={setExamAnswer}
                                labeling={examData?.labeling}
                                handleSubmit={handleSubmit}
                                selectPrevExam={selectPrevExam}
                                handleSkip={handleSkip}
                            />
                        </Grid>
                    </Container>

                    {selectedExam && !isLoadingExams && !isLoadingSelectedExam && <QualityViewHeadlineSection view={selectedExam?.view} />}
                    {/* clips section */}
                    {qualityType === 'compare' ? (
                        <QualityCompareClipsSection
                            isLoadingSelectedExam={isLoadingSelectedExam}
                            isLoadingExams={isLoadingExams}
                            pairData={examData}
                            selectedExam={selectedExam}
                            setLoadedClipsCounter={setLoadedClipsCounter}
                        />
                    ) : (
                        <QualityDiagnosableClipsSection
                            isLoadingSelectedExam={isLoadingSelectedExam}
                            isLoadingExams={isLoadingExams}
                            examData={examData}
                            selectedExam={selectedExam}
                            setLoadedClipsCounter={setLoadedClipsCounter}
                        />
                    )}
                </>
            )}
        </>
    )
}
