import { useEffect } from 'react'

export default function useClipKeyboardShortcuts(playPauseButton, rewindButton, oneFrameBackButton, oneFrameForwardButton, goToEndButton) {
    // adds keydown listener to the whole document
    useEffect(() => {
        document.addEventListener('keydown', registerKeyboardEvents)

        return function cleanup() {
            document.removeEventListener('keydown', registerKeyboardEvents)
        }
    }, [])

    function registerKeyboardEvents(event) {
        switch (event.key) {
            case ' ':
                event.preventDefault()
                playPauseButton.current?.click()
                break
            case 'w':
                rewindButton.current?.click()
                break
            case ',':
            case '[':
            case '0':
                oneFrameBackButton.current?.click()
                break
            case '.':
            case ']':
                oneFrameForwardButton.current?.click()
                break
            case 'c':
                goToEndButton.current?.click()
                break
            default:
                return
        }
    }
}
