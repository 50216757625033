import { useState, useEffect, useRef, useContext } from 'react'
import Container from '@mui/material/Container'
import Amplify, { Auth } from 'aws-amplify'
import { Context } from '../App'
import LoginCard from '../components/login/LoginCard'
import PasswordVerificationCard from '../components/login/PasswordVerificationCard'
import ForgotPasswordCard from '../components/login/ForgotPasswordCard'
import NewPasswordCard from '../components/login/NewPasswordCard'

Amplify.configure({
    Auth: {
        userPoolId: process.env.REACT_APP_AUTH_USERPOOLID,
        region: 'us-east-2',
        userPoolWebClientId: process.env.REACT_APP_AUTH_USERPOOLWEBCLIENTID
    }
})

export default function Login({ authenticateUser }) {
    const AppContext = useContext(Context)
    const [user, setUser] = useState(null)
    const [username, setUsername] = useState('') // fill in for easy download
    const [password, setPassword] = useState('') // fill in for easy download
    const [usernameError, setUsernameError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [isWaitingForVerification, setIsWaitingForVerification] = useState(false)
    const [verificationCode, setVerificationCode] = useState('')
    const [verificationCodeError, setVerificationCodeError] = useState(false)
    const [newPasswordRequired, setNewPasswordRequired] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordError, setNewPasswordError] = useState(false)
    const newPasswordElement = useRef()
    const verificationCodeElement = useRef()
    const ref = useRef({ newPasswordElement, verificationCodeElement })

    function handleFetchCatch(err) {
        const message = err.message ? err.message : 'A problem occurred'
        AppContext?.showToast(message)
        console.log(err)
    }

    async function loginUser(username) {
        try {
            const user = await Auth.signIn(username, password)
            setUser(user)
            console.log(user)
            // handle new password setup
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                console.log('new password required')
                setNewPasswordRequired(true)
                return false
            }
            return true
        } catch (err) {
            handleFetchCatch(err)
            return false
        }
    }

    useEffect(() => {
        if (newPasswordRequired === true) {
            newPasswordElement.current.value = ''
        }
    }, [newPasswordRequired])

    const handleSubmit = async () => {
        setUsernameError(false)
        setPasswordError(false)

        if (username === '') {
            setUsernameError(true)
        }

        if (password === '') {
            setPasswordError(true)
        }

        if (username && password) {
            const auth = await loginUser(username)
            if (auth === true) {
                authenticateUser()
            }
        }
    }

    async function handleSubmitNewPassword() {
        if (user === null) {
            return
        }
        setNewPasswordError(false)
        if (newPassword === '') {
            setNewPasswordError(true)
        } else {
            try {
                const res = await Auth.completeNewPassword(user, newPassword)
                console.log(res)
                authenticateUser()
            } catch (err) {
                handleFetchCatch(err)
            }
        }
    }

    async function handleForgotPassword() {
        setUsernameError(false)
        if (username === '') {
            setUsernameError(true)
            return
        }
        try {
            // Send confirmation code to user's email
            const data = await Auth.forgotPassword(username)
            setIsWaitingForVerification(true)
            if (data.CodeDeliveryDetails) {
                AppContext?.showToast(`An ${data.CodeDeliveryDetails.AttributeName} was sent to ${data.CodeDeliveryDetails.Destination}`)
            }
        } catch (err) {
            handleFetchCatch(err)
        }
    }

    useEffect(() => {
        if (isWaitingForVerification === true) {
            verificationCodeElement.current.value = ''
        }
    }, [isWaitingForVerification])

    async function handleVerificationCode() {
        setVerificationCodeError(false)
        setNewPasswordError(false)
        if (verificationCode === '') {
            setVerificationCodeError(true)
            return
        }
        if (newPassword === '') {
            setNewPasswordError(true)
            return
        }
        try {
            // Collect confirmation code and new password, then
            const data = await Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
            if (data === 'SUCCESS') {
                AppContext?.showToast('Password changed successfully')
                setTimeout(() => {
                    setShowForgotPassword(false)
                    setIsWaitingForVerification(false)
                }, 2000)
            }
        } catch (err) {
            handleFetchCatch(err)
        }
    }

    return (
        <Container>
            {newPasswordRequired && !showForgotPassword ? (
                // new password screen
                <Container>
                    <NewPasswordCard setNewPassword={setNewPassword} newPasswordError={newPasswordError} handleSubmitNewPassword={handleSubmitNewPassword} ref={newPasswordElement} />
                </Container>
            ) : showForgotPassword && !isWaitingForVerification ? (
                // forgot password screen
                <Container>
                    <ForgotPasswordCard setUsername={setUsername} usernameError={usernameError} handleForgotPassword={handleForgotPassword} />
                </Container>
            ) : showForgotPassword && isWaitingForVerification ? (
                // verification screen
                <Container>
                    <PasswordVerificationCard
                        setVerificationCode={setVerificationCode}
                        setNewPassword={setNewPassword}
                        handleVerificationCode={handleVerificationCode}
                        verificationCodeError={verificationCodeError}
                        newPasswordError={newPasswordError}
                        ref={ref}
                    />
                </Container>
            ) : (
                // login screen
                <Container>
                    <LoginCard
                        setUsername={setUsername}
                        setPassword={setPassword}
                        setShowForgotPassword={setShowForgotPassword}
                        handleSubmit={handleSubmit}
                        usernameError={usernameError}
                        passwordError={passwordError}
                    />
                </Container>
            )}
        </Container>
    )
}
