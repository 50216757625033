import { useState } from 'react'
import { Divider, Stack, IconButton, Typography } from '@mui/material'
import { PlayArrowRounded, PauseRounded } from '@mui/icons-material'

export default function QualityCardActions({ playFunction, pauseFunction, currentFrame, framesCount }) {
    const [isPlaying, setIsPlaying] = useState(true)

    function handlePlay() {
        setIsPlaying(true)
        playFunction()
    }

    function handlePause() {
        setIsPlaying(false)
        pauseFunction()
    }

    function handlePlayPause() {
        if (isPlaying) {
            handlePause()
        } else {
            handlePlay()
        }
    }

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <Divider orientation="vertical" flexItem />
            <IconButton onClick={handlePlayPause}>{isPlaying ? <PauseRounded /> : <PlayArrowRounded />}</IconButton>
            <Divider orientation="vertical" flexItem />
            {framesCount > 0 && (
                <Typography justifyContent="">
                    {currentFrame + 1}/{framesCount}
                </Typography>
            )}
        </Stack>
    )
}
