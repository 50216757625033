export const viewQuestions = {
                                 "4C": [
                                     {
                                         "name": "four_c_la_observed",
                                         "displayName": "<h4>LA (left Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LA (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Some LA visible at LOW quality or Near 4C</font></li><li><font color=\"#0e738f\">Other: Aqua - LA not visible and not near 4C</font></li></ul></h4>",
                                         "greenClip": "/4C/four_c_la_observed/1567602208942_1567602210131_4.gif",
                                         "redClip": "/4C/four_c_la_observed/1627589647246_1627589648938_4.gif"
                                     },
                                     {
                                         "name": "four_c_ra_observed",
                                         "displayName": "<h4>RA (right Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality RA (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Some RA visible at LOW quality or Near 4C</font></li><li><font color=\"#0e738f\">Other: Aqua - RA not visible and not near 4C</font></li></ul></h4>",
                                         "greenClip": "/4C/four_c_ra_observed/1567602208942_1567602210131_4.gif",
                                         "redClip": "/4C/four_c_ra_observed/1626984629405_1626984631024_4.gif"
                                     },
                                     {
                                         "name": "four_c_lv_observed",
                                         "displayName": "<h4>LV (left Ventricle) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LV (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Some LV visible at LOW quality</font></li><li><font color=\"#0e738f\">Other: Aqua - LV not visible</font></li></ul></h4>",
                                         "greenClip": "/4C/four_c_lv_observed/1567602208942_1567602210131_4.gif",
                                         "redClip": "/4C/four_c_lv_observed/1607257945150_1607257946163_4.gif"
                                     },
                                     {
                                         "name": "four_c_rv_observed",
                                         "displayName": "<h4>RV (right Ventricle) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality RV (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Some RV visible at LOW quality</font></li><li><font color=\"#0e738f\">Other: Aqua - RV not visible</font></li></ul></h4>",
                                         "greenClip": "/4C/four_c_rv_observed/1567602208942_1567602210131_4.gif",
                                         "redClip": "/4C/four_c_rv_observed/1576759648437_1576759650815_4.gif"
                                     },
                                     {
                                         "name": "four_c_mv_observed",
                                         "displayName": "<h4>MV (mitral valve) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality MV (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - No MV or Low quality MV + Surrounding anatomy is visible</font></li><li><font color=\"#0e738f\">Other: Aqua - Surrounding anatomy of MV not visible</font></li></ul></h4>",
                                         "greenClip": "/4C/four_c_mv_observed/1567602208942_1567602210131_4.gif",
                                         "redClip": "/4C/four_c_mv_observed/1609160686607_1609160687696_4.gif"
                                     },
                                     {
                                         "name": "four_c_av",
                                         "displayName": "<h4>ANY of the AV (Aortic valve) or Aorta are observed?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - ANY AV or Aorta observed (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - 4C or near 4C + No AV or Aorta observed</font></li><li><font color=\"#0e738f\">Other: Aqua - Surrounding anatomy of Aorta and AV not visible</font></li></ul></h4>",
                                         "greenClip": "/4C/four_c_av/1608631616961_1608631618355_4.gif",
                                         "redClip": "/4C/four_c_av/1567602208942_1567602210131_4.gif"
                                     },
                                     {
                                         "name": "four_c_apex_center",
                                         "displayName": "<h4>Apex near center of the image?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Apex near center (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Apex visible + Apex not near center</font></li><li><font color=\"#0e738f\">Other: Aqua - Apex not visible</font></li></ul></h4>",
                                         "greenClip": "/4C/four_c_apex_center/1567602208942_1567602210131_4.gif",
                                         "redClip": "/4C/four_c_apex_center/1608631616961_1608631618355_4.gif"
                                     },
                                     {
                                         "name": "four_c_septum_vertical",
                                         "displayName": "<h4>Inter Ventricular septum is vertical?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Septum is vertical (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Septum is Diagonal</font></li><li><font color=\"#0e738f\">Other: Aqua - Septum not visible</font></li></ul></h4>",
                                         "greenClip": "/4C/four_c_septum_vertical/1567602208942_1567602210131_4.gif",
                                         "redClip": "/4C/four_c_septum_vertical/1628704592814_1628704594873_4.gif"
                                     },
                                     {
                                         "name": "four_c_foreshortened",
                                         "displayName": "<h4>4C is foreshortened?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Foreshortened 4C</font></li><li><font color=\"#BE43F7\">No: Purple - 4C Apex visible + 4C Apex NOT foreshortened</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>4C Apex not visible</li><li>2C / 3C / 5C</li></ul></font></li></ul></h4>",
                                         "greenClip": "/4C/four_c_foreshortened/1609836860071_1609836860810_4.gif",
                                         "redClip": "/4C/four_c_foreshortened/1567602208942_1567602210131_4.gif"
                                     }
                                 ],
                                 "5C": [
                                     {
                                         "name": "five_c_la_observed",
                                         "displayName": "<h4>LA (left Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LA (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Some LA visible at LOW quality</font></li><li><font color=\"#0e738f\">Other: Aqua - LA not visible</font></li></ul></h4>",
                                         "greenClip": "/5C/five_c_la_observed/5C_rock_view.gif",
                                         "redClip": "/5C/five_c_la_observed/1609765621208_1609765623787_7.gif"
                                     },
                                     {
                                         "name": "five_c_ra_observed",
                                         "displayName": "<h4>5C or 4C RA (right Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality 5C or 4C RA</font></li><li><font color=\"#BE43F7\">No: Purple - Some RA visible at LOW quality or in vicinity of 5C or 4C</font></li><li><font color=\"#0e738f\">Other: Aqua - RA not visible and not in vicinity of 5C and 4C</font></li></ul></h4>",
                                         "greenClip": "/5C/five_c_ra_observed/5C_rock_view.gif",
                                         "redClip": "/5C/five_c_ra_observed/1627579877520_1627579878733_7.gif"
                                     },
                                     {
                                         "name": "five_c_lv_observed",
                                         "displayName": "<h4>5C or 4C LV (left Ventricle) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality 5C or 4C LV</font></li><li><font color=\"#BE43F7\">No: Purple - Some 5C or 4C LV visible at LOW quality</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>LV not visible</li><li>2C or 3C like LV</li></ul></font></li></ul></h4>",
                                         "greenClip": "/5C/five_c_lv_observed/5C_rock_view.gif",
                                         "redClip": "/5C/five_c_lv_observed/5C_tilt_+.gif"
                                     },
                                     {
                                         "name": "five_c_rv_observed",
                                         "displayName": "<h4>5C or 4C RV (right Ventricle) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality 5C or 4C RV</font></li><li><font color=\"#BE43F7\">No: Purple - Some 5C or 4C RV visible at LOW quality or in vicinity of 5C or 4C</font></li><li><font color=\"#0e738f\">Other: Aqua - RV not visible and not in vicinity of 5C and 4C</font></li></ul></h4>",
                                         "greenClip": "/5C/five_c_rv_observed/5C_rock_view.gif",
                                         "redClip": "/5C/five_c_rv_observed/1627928900032_1627928904519_7.gif"
                                     },
                                     {
                                         "name": "five_c_mv_observed",
                                         "displayName": "<h4>MV (mitral valve) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality MV (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - No MV or Low quality MV + Surrounding anatomy is visible</font></li><li><font color=\"#0e738f\">Other: Aqua - Surrounding anatomy of MV not visible</font></li></ul></h4>",
                                         "greenClip": "/5C/five_c_mv_observed/5C_slide y_view.gif",
                                         "redClip": "/5C/five_c_mv_observed/1608557099339_1608557101332_7.gif"
                                     },
                                     {
                                         "name": "five_c_av_observed",
                                         "displayName": "<h4>AV (Aortic valve) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality 5C AV</font></li><li><font color=\"#BE43F7\">No: Purple - No AV or Low quality AV + Surrounding anatomy is visible</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Surrounding anatomy of AV not visible</li><li>3C AV</li></ul></font></li></ul></h4>",
                                         "greenClip": "/5C/five_c_av_observed/5C_rock_view.gif",
                                         "redClip": "/5C/five_c_av_observed/1630609964015_1630609964822_7.gif"
                                     },
                                     {
                                         "name": "five_c_apex_center",
                                         "displayName": "<h4>Apex near center of the image?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Apex near center (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Apex visible + Apex not near center</font></li><li><font color=\"#0e738f\">Other: Aqua - Apex not visible</font></li></ul></h4>",
                                         "greenClip": "/5C/five_c_apex_center/5C_rock_view.gif",
                                         "redClip": "/5C/five_c_apex_center/5C_slide x_+.gif"
                                     },
                                     {
                                         "name": "five_c_lvot",
                                         "displayName": "<h4>5C LVOT (LV outflow tract) vertical towards ultrasound beam?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - 5C LVOT suitable for CW Doppler</font></li><li><font color=\"#BE43F7\">No: Purple - LVOT NOT suitable for CW Doppler + Some 5C LVOT or AV visible</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>5C LVOT and AV not visible</li><li>3C AV</li></ul></font></li></ul></h4>",
                                         "greenClip": "/5C/five_c_lvot/5C_rock_view.gif",
                                         "redClip": "/5C/five_c_lvot/1627929113693_1627929115105_7.gif"
                                     },
                                     {
                                         "name": "five_c_septum_vertical",
                                         "displayName": "<h4>Inter Ventricular septum is vertical?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Septum is vertical (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Septum is Diagonal</font></li><li><font color=\"#0e738f\">Other: Aqua - Septum not visible</font></li></ul></h4>",
                                         "greenClip": "/5C/five_c_septum_vertical/5C_rock_view.gif",
                                         "redClip": "/5C/five_c_septum_vertical/5C_rock_++.gif"
                                     }
                                 ],
                                 "3C": [
                                     {
                                         "name": "three_c_la_observed",
                                         "displayName": "<h4>LA (left Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LA (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Some LA visible at LOW quality</font></li><li><font color=\"#0e738f\">Other: Aqua - LA not visible</font></li></ul></h4>",
                                         "greenClip": "/3C/three_c_la_observed/1570456693873_1570456695379_6.gif",
                                         "redClip": "/3C/three_c_la_observed/3C_tilt_++.gif"
                                     },
                                     {
                                         "name": "three_c_lv_observed",
                                         "displayName": "<h4>LV (left Ventricle) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LV (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Some LV visible at LOW quality</font></li><li><font color=\"#0e738f\">Other: Aqua - LV not visible</font></li></ul></h4>",
                                         "greenClip": "/3C/three_c_lv_observed/1570456693873_1570456695379_6.gif",
                                         "redClip": "/3C/three_c_lv_observed/3C_slide y_+.gif"
                                     },
                                     {
                                         "name": "three_c_mv_observed",
                                         "displayName": "<h4>MV (mitral valve) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality MV (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - No MV or Low quality MV + Surrounding anatomy is visible</font></li><li><font color=\"#0e738f\">Other: Aqua - Surrounding anatomy of MV not visible</font></li></ul></h4>",
                                         "greenClip": "/3C/three_c_mv_observed/1570456693873_1570456695379_6.gif",
                                         "redClip": "/3C/three_c_mv_observed/1607423805538_1607423806359_6.gif"
                                     },
                                     {
                                         "name": "three_c_av_observed",
                                         "displayName": "<h4>AV (Aortic valve) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality 3C AV</font></li><li><font color=\"#BE43F7\">No - All of the following occur:<ul><li>No 3C AV or Low quality 3C AV</li><li>Near 3C or 2C</font></li></ul></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Surrounding anatomy of AV not visible (also not 2C)</li><li>5C AV</li></ul></font></li></ul></h4>",
                                         "greenClip": "/3C/three_c_av_observed/3C_slide y_view.gif",
                                         "redClip": "/3C/three_c_av_observed/1627589754935_1627589756673_6.gif"
                                     },
                                     {
                                         "name": "three_c_lvot",
                                         "displayName": "<h4>3C LVOT (LV outflow tract) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LVOT</font></li><li><font color=\"#BE43F7\">No: Purple - Some LVOT visible at LOW quality or 3C AV visible</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>LVOT and AV not visible</li><li>5C AV</li></ul></font></li></ul></h4>",
                                         "greenClip": "/3C/three_c_lvot/3C_slide y_view.gif",
                                         "redClip": "/3C/three_c_lvot/1627589754935_1627589756673_6.gif"
                                     },
                                     {
                                         "name": "three_c_foreshortened",
                                         "displayName": "<h4>3C is foreshortened?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Foreshortened 3C</font></li><li><font color=\"#BE43F7\">No: Purple - 3C Apex visible + 3C Apex NOT foreshortened</font></li><li><font color=\"#0e738f\"><ul><li>3C Apex not visible</li><li>2C / 4C / 5C</li></ul></font></li></ul></h4>",
                                         "greenClip": "/3C/three_c_foreshortened/3C_slide y_+.gif",
                                         "redClip": "/3C/three_c_foreshortened/1570456693873_1570456695379_6.gif"
                                     }
                                 ],
                                 "2C": [
                                     {
                                         "name": "two_c_la_observed",
                                         "displayName": "<h4>LA (left Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LA (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - Some LA visible at LOW quality or surrounding anatomy of LA visible</font></li><li><font color=\"#0e738f\">Other: Aqua - Surrounding anatomy of LA not visible</font></li></ul></h4>",
                                         "greenClip": "/2C/two_c_la_observed/1608557404769_1608557405674_5.gif",
                                         "redClip": "/2C/two_c_la_observed/1629835399080_1629835400679_5.gif"
                                     },
                                     {
                                         "name": "two_c_mv_observed",
                                         "displayName": "<h4>2C:MV (mitral valve) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality MV (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - No MV or Low quality MV + Surrounding anatomy is visible</font></li><li><font color=\"#0e738f\">Other: Aqua - Surrounding anatomy of MV not visible</font></li></ul></h4>",
                                         "greenClip": "/2C/two_c_mv_observed/1608557404769_1608557405674_5.gif",
                                         "redClip": "/2C/two_c_mv_observed/1609840335200_1609840336069_5.gif"
                                     },
                                     {
                                         "name": "two_c_foreshortened",
                                         "displayName": "<h4>2C is foreshortened?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Foreshortened 2C</font></li><li><font color=\"#BE43F7\">No: Purple - 2C Apex visible + 2C Apex NOT foreshortened</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>2C Apex not visible</li><li>3C / 4C / 5C</li></ul></font></li></ul></h4>",
                                         "greenClip": "/2C/two_c_foreshortened/1609162308128_1609162309075_5.gif",
                                         "redClip": "/2C/two_c_foreshortened/1608557404769_1608557405674_5.gif"
                                     },
                                     {
                                         "name": "two_c_rv",
                                         "displayName": "<h4>ANY of the RV (right Ventricle) is observed?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - ANY RV observed (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - 2C or near 2C + No RV observed</font></li><li><font color=\"#0e738f\">Other: Aqua - Surrounding anatomy of RV not visible</font></li></ul></h4>",
                                         "greenClip": "/2C/two_c_rv/1626984912322_1626984913269_5.gif",
                                         "redClip": "/2C/two_c_rv/1608557404769_1608557405674_5.gif"
                                     },
                                     {
                                         "name": "two_c_inferior",
                                         "displayName": "<h4>Left hand side (LHS) edge of LV (wall or septum) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LHS edge of LV (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - No LHS edge or Low quality LHS edge + Surrounding anatomy is visible</font></li><li><font color=\"#0e738f\">Other: Aqua - Surrounding anatomy of Apical LV wall not visible</font></li></ul></h4>",
                                         "greenClip": "/2C/two_c_inferior/1608557404769_1608557405674_5.gif",
                                         "redClip": "/2C/two_c_inferior/1608625670285_1608625681011_5.gif"
                                     },
                                     {
                                         "name": "two_c_anterior",
                                         "displayName": "<h4>Right hand side (RHS) wall of LV observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality RHS wall of LV (anywhere in Apical window)</font></li><li><font color=\"#BE43F7\">No: Purple - No RHS wall or Low quality RHS wall + Surrounding anatomy is visible</font></li><li><font color=\"#0e738f\">Other: Aqua - Surrounding anatomy of Apical LV wall not visible</font></li></ul></h4>",
                                         "greenClip": "/2C/two_c_anterior/1608557404769_1608557405674_5.gif",
                                         "redClip": "/2C/two_c_anterior/1608558897019_1608558900418_5.gif"
                                     }
                                 ],
                                 "PLAX": [
                                     {
                                         "name": "plax_av",
                                         "displayName": "<h4>AV (Aortic valve) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality AV</font></li><li><font color=\"#BE43F7\">No: Purple - Low quality AV or No AV + Surrounding anatomy is visible</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Surrounding anatomy of AV not visible</li><li>Short Axis / RPLAX</li></ul></font></li></ul></h4>",
                                         "greenClip": "/PLAX/plax_av/1606053737349_1606053746218_0.gif",
                                         "redClip": "/PLAX/plax_av/1606049302016_1606049310769_0.gif"
                                     },
                                     {
                                         "name": "plax_la_observed",
                                         "displayName": "<h4>LA (left Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LA</font></li><li><font color=\"#BE43F7\">No: Purple - Some LA visible at LOW quality</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>LA not visible</li><li>Short Axis / RPLAX</li></ul></font></li></ul></h4>",
                                         "greenClip": "/PLAX/plax_la_observed/1606053737349_1606053746218_0.gif",
                                         "redClip": "/PLAX/plax_la_observed/1606056540860_1606056542622_0.gif"
                                     },
                                     {
                                         "name": "plax_rv_observed",
                                         "displayName": "<h4>PLAX RV (right Ventricle) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality PLAX RV</font></li><li><font color=\"#BE43F7\">No: Purple - Some PLAX RV visible at LOW quality</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>PLAX like RV not visible</li><li>Short Axis / RPLAX</li></ul></font></li></ul></h4>",
                                         "greenClip": "/PLAX/plax_rv_observed/1606053737349_1606053746218_0.gif",
                                         "redClip": "/PLAX/plax_rv_observed/1609159295656_1609159304653_0.gif"
                                     },
                                     {
                                         "name": "plax_lvot_observed",
                                         "displayName": "<h4>LVOT (LV outflow tract) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LVOT</font></li><li><font color=\"#BE43F7\">No: Purple - Some LVOT visible at LOW quality</font></li><li><font color=\"#0e738f\">Other: Aqua - LVOT and AV not visible / RPLAX</font></li></ul></h4>",
                                         "greenClip": "/PLAX/plax_lvot_observed/1606053737349_1606053746218_0.gif",
                                         "redClip": "/PLAX/plax_lvot_observed/1607352078808_1607352080438_0.gif"
                                     },
                                     {
                                         "name": "plax_apex_not_observed",
                                         "displayName": "<h4>Apex visible or foreshortened?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Apex visible or Foreshortened</font></li><li><font color=\"#BE43F7\">No: Purple - PLAX like LV visible + Apex not visible and LV not Foreshortened </font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>LV not visible</li><li>Short Axis / RPLAX</li></ul></font></li></ul></h4>",
                                         "greenClip": "/PLAX/plax_apex_not_observed/1608619473689_1608619475852_0.gif",
                                         "redClip": "/PLAX/plax_apex_not_observed/1606053737349_1606053746218_0.gif"
                                     },
                                     {
                                         "name": "plax_lv_perpendicular",
                                         "displayName": "<h4>LV (left ventricle) perpendicular to ultrasound beam?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - LV perpendicular to beam</font></li><li><font color=\"#BE43F7\">No: Purple - Some PLAX like LV visible + LV in Diagonal</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>LV not visible</li><li>Short Axis / RPLAX</li></ul></font></li></ul></h4>",
                                         "greenClip": "/PLAX/plax_lv_perpendicular/1606053737349_1606053746218_0.gif",
                                         "redClip": "/PLAX/plax_lv_perpendicular/1609762091626_1609762092367_0.gif"
                                     }
                                 ],
                                 "SAX_MV": [
                                     {
                                         "name": "mv_anterior_leaflet_observed",
                                         "displayName": "<h4>AMVL (Anterior mitral valve leaflet) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality SAX MV AMVL</font></li><li><font color=\"#BE43F7\">No: Purple - No AMVL or Low quality AMVL + Surrounding anatomy (SAX MV or SAX PAP) is visible</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Surrounding anatomy of AMVL not visible</li><li>SAX AV / Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_MV/mv_anterior_leaflet_observed/1607349978656_1607349980053_2.gif",
                                         "redClip": "/SAX_MV/mv_anterior_leaflet_observed/1607429529872_1607429530751_2.gif"
                                     },
                                     {
                                         "name": "mv_posterior_leaflet_observed",
                                         "displayName": "<h4>PMVL (Posterior mitral valve leaflet) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality SAX MV PMVL</font></li><li><font color=\"#BE43F7\">No: Purple - No PMVL or Low quality PMVL + Surrounding anatomy (SAX MV or SAX PAP) is visible</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Surrounding anatomy of PMVL not visible</li><li>SAX AV / Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_MV/mv_posterior_leaflet_observed/1607349978656_1607349980053_2.gif",
                                         "redClip": "/SAX_MV/mv_posterior_leaflet_observed/1607348456297_1607348457027_2.gif"
                                     },
                                     {
                                         "name": "mv_no_pap",
                                         "displayName": "<h4>ANY Papilary muscles observed?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - ANY Papilary muscle observed (including SAX PAP)</font></li><li><font color=\"#BE43F7\">No: Purple - SAX MV or near SAX MV + No Papilary muscle observed</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Surrounding anatomy of Papilary muscles not visible<li>SAX AV / SAX Apex</li></li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_MV/mv_no_pap/1606054346729_1606054347535_1.gif",
                                         "redClip": "/SAX_MV/mv_no_pap/1607349978656_1607349980053_2.gif"
                                     },
                                     {
                                         "name": "mv_lv_round",
                                         "displayName": "<h4>LV (left ventricle) appears round shaped?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Round SAX MV or SAX PAP like LV</font></li><li><font color=\"#BE43F7\">No: Purple - SAX MV or SAX PAP like LV visible + LV is not round</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>LV not visible</li><li>SAX AV / SAX Apex/ Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_MV/mv_lv_round/1607349978656_1607349980053_2.gif",
                                         "redClip": "/SAX_MV/mv_lv_round/1608632143283_1608632146587_2.gif"
                                     },
                                     {
                                         "name": "mv_lv_center",
                                         "displayName": "<h4>LV (left ventricle) near center of the image?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - SAX MV or SAX PAP like LV near center </font></li><li><font color=\"#BE43F7\">No: Purple - Some SAX MV or SAX PAP like LV visible + LV not near center</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>LV not visible</li><li>SAX AV / SAX Apex / Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_MV/mv_lv_center/1607349978656_1607349980053_2.gif",
                                         "redClip": "/SAX_MV/mv_lv_center/SAX MV_rock_--.gif"
                                     }
                                 ],
                                 "SAX_PAP": [
                                     {
                                         "name": "pap_rv_observed",
                                         "displayName": "<h4>Short axis RV (right Ventricle) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality short axis RV</font></li><li><font color=\"#BE43F7\">No: Purple - Some short axis RV visible at LOW quality</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Short axis RV not visible</li><li>SAX AV / Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_PAP/pap_rv_observed/1606053751811_1606053753039_1.gif",
                                         "redClip": "/SAX_PAP/pap_rv_observed/1630435900188_1630435901005_1.gif"
                                     },
                                     {
                                         "name": "pap_muscles_observed",
                                         "displayName": "<h4>Both papilary muscles observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality papilary muscles (in SAX PAP)</font></li><li><font color=\"#BE43F7\">No: Any of:<ul><li>0-1 papilary muscles / Low quality papilary muscles + Surrounding anatomy is visible</li><li>SAX MV / SAX Apex</li></ul></font></li><li><font color=\"#0e738f\">Any of:<ul><li>Surrounding anatomy of papilary muscles not visible</li><li>SAX AV / Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_PAP/pap_muscles_observed/1606053751811_1606053753039_1.gif",
                                         "redClip": "/SAX_PAP/pap_muscles_observed/1609844642337_1609844644967_1.gif"
                                     },
                                     {
                                         "name": "pap_no_mv",
                                         "displayName": "<h4>ANY Short Axis MV (mitral valve) observed?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - ANY Short Axis MV observed</font></li><li><font color=\"#BE43F7\">No: Purple - SAX PAP or near SAX PAP + No MV observed</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Surrounding anatomy of MV not visible</li><li>SAX AV / SAX Apex / Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_PAP/pap_no_mv/1606054346729_1606054347535_1.gif",
                                         "redClip": "/SAX_PAP/pap_no_mv/1606053751811_1606053753039_1.gif"
                                     },
                                     {
                                         "name": "pap_lv_round",
                                         "displayName": "<h4>LV (left ventricle) appears round shaped?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Round SAX PAP or SAX MV like LV</font></li><li><font color=\"#BE43F7\">No: Purple - SAX PAP or SAX MV like LV visible + LV is not round</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>LV not visible</li><li>SAX AV / Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_PAP/pap_lv_round/1606053751811_1606053753039_1.gif",
                                         "redClip": "/SAX_PAP/pap_lv_round/1627929614230_1627929615072_1.gif"
                                     },
                                     {
                                         "name": "pap_lv_center",
                                         "displayName": "<h4>LV (left ventricle) near center of the image?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - SAX PAP or SAX MV like LV near center </font></li><li><font color=\"#BE43F7\">No: Purple - Some SAX PAP or SAX MV like LV visible + LV not near center</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>LV not visible</li><li>SAX AV / Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_PAP/pap_lv_center/1606053751811_1606053753039_1.gif",
                                         "redClip": "/SAX_PAP/pap_lv_center/SAX PAP_rock_+.gif"
                                     }
                                 ],
                                 "SAX_AV": [
                                     {
                                         "name": "av_la_observed",
                                         "displayName": "<h4>SAX AV LA (left Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality SAX AV LA</font></li><li><font color=\"#BE43F7\">No: Purple - Some SAX AV LA visible at LOW quality</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>SAX AV LA not visible</li><li>SAX PAP / SAX MV</li><li>Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_AV/av_la_observed/1628626308029_1628626314041_3.gif",
                                         "redClip": "/SAX_AV/av_la_observed/1607258546210_1607258555661_3.gif"
                                     },
                                     {
                                         "name": "av_ra_observed",
                                         "displayName": "<h4>RA (right Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality RA</font></li><li><font color=\"#BE43F7\">No: Purple - Some RA visible at LOW quality or SAX AV with no RA</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>RA not visible</li><li>SAX PAP / SAX MV</li><li>Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_AV/av_ra_observed/1609844621940_1609844623323_3.gif",
                                         "redClip": "/SAX_AV/av_ra_observed/SAX AV_rock_--.gif"
                                     },
                                     {
                                         "name": "av_leaflets",
                                         "displayName": "<h4>All AV (aortic valve) leaflets observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - 3 HIGH quality AV leaflets</font></li><li><font color=\"#BE43F7\">No: Purple - 0-2 AV leaflets / Low quality AV leaflets + Surrounding anatomy is visible</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Surrounding anatomy of AV leaflets not visible</li><li>SAX PAP / SAX MV</li><li>Long Axis</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SAX_AV/av_leaflets/1628626308029_1628626314041_3.gif",
                                         "redClip": "/SAX_AV/av_leaflets/1629916961793_1629916963163_3.gif"
                                     }
                                 ],
                                 "SC_4C": [
                                     {
                                         "name": "sc4c_la",
                                         "displayName": "<h4>LA (left Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LA</font></li><li><font color=\"#BE43F7\">No: Purple - Some LA visible at LOW quality</font></li><li><font color=\"#0e738f\">Other: Aqua - LA not visible</font></li></ul></h4>",
                                         "greenClip": "/SC_4C/sc4c_la/1630607657404_1630607659480_8.gif",
                                         "redClip": "/SC_4C/sc4c_la/1711438303646_1711438306008_8.gif"
                                     },
                                     {
                                         "name": "sc4c_ra",
                                         "displayName": "<h4>SC 4C RA (right Atrium) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality SC 4C RA</font></li><li><font color=\"#BE43F7\">No: Purple - Some SC 4C RA visible at LOW quality or surrounding anatomy visible</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>SC 4C RA and surrounding anatomy not visible</li><li>SC IVC</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SC_4C/sc4c_ra/1630607657404_1630607659480_8.gif",
                                         "redClip": "/SC_4C/sc4c_ra/1630435965588_1630435968395_8.gif"
                                     },
                                     {
                                         "name": "sc4c_rv",
                                         "displayName": "<h4>SC 4C RV (right Ventricle) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality SC 4C RV</font></li><li><font color=\"#BE43F7\">No: Purple - Some SC 4C RV visible at LOW quality</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>SC 4C RV or Surrounding anatomy not visible</li><li>SC IVC</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SC_4C/sc4c_rv/1630607657404_1630607659480_8.gif",
                                         "redClip": "/SC_4C/sc4c_rv/1607424584013_1607424589313_8.gif"
                                     },
                                     {
                                         "name": "sc4c_lv",
                                         "displayName": "<h4>LV (left Ventricle) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality LV</font></li><li><font color=\"#BE43F7\">No: Purple - Some LV visible at LOW quality</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>LV or Surrounding anatomy not visible</li><li>SC IVC</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SC_4C/sc4c_lv/1630607657404_1630607659480_8.gif",
                                         "redClip": "/SC_4C/sc4c_lv/SC 4C_tilt_-.gif"
                                     },
                                     {
                                         "name": "sc4c_mv",
                                         "displayName": "<h4>MV (mitral valve) observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality MV</font></li><li><font color=\"#BE43F7\">No: Purple - No MV or Low quality MV + Surrounding anatomy is visible</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>MV or Surrounding anatomy not visible</li><li>SC IVC</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SC_4C/sc4c_mv/1630607657404_1630607659480_8.gif",
                                         "redClip": "/SC_4C/sc4c_mv/1609234395473_1609234403611_8.gif"
                                     },
                                     {
                                         "name": "sc4c_av_not_observed",
                                         "displayName": "<h4>ANY of the AV (Aortic valve) is observed?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - ANY AV observed</font></li><li><font color=\"#BE43F7\">No: Purple - SC 4C or near SC 4C + No AV observed</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Surrounding anatomy of SC 4C not visible</li><li>SC IVC</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SC_4C/sc4c_av_not_observed/1568208309806_1568208311550_8.gif",
                                         "redClip": "/SC_4C/sc4c_av_not_observed/1630607657404_1630607659480_8.gif"
                                     }
                                 ],
                                 "SC_IVC": [
                                     {
                                         "name": "scivc_ra_ivc",
                                         "displayName": "<h4>RA (right Atrium)-IVC connection is observed in HIGH quality?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - HIGH quality RA-IVC connection + Open IVC</font></li><li><font color=\"#BE43F7\">No: Purple - Some IVC RA visible but RA-IVC connection is not visible or of low quality</font></li><li><font color=\"#0e738f\">Other - any of:<ul><li>RA and IVC are not visible</li><li>SC 4C / Descending aorta</li></font></ul></li></ul></h4>",
                                         "greenClip": "/SC_IVC/scivc_ra_ivc/1606054500341_1606054504773_9.gif",
                                         "redClip": "/SC_IVC/scivc_ra_ivc/1630607712545_1630607713830_9.gif"
                                     },
                                     {
                                         "name": "scivc_max_cut",
                                         "displayName": "<h4>IVC seems at maximal cut?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - Maximal cut IVC connection</font></li><li><font color=\"#BE43F7\">No: Purple - Some IVC is visible but Not at maximal cut</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>IVC is not visible</li><li>Descending Aorta with no IVC</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SC_IVC/scivc_max_cut/1703578699437_1703578704331_9.gif",
                                         "redClip": "/SC_IVC/scivc_max_cut/1703578676235_1703578680896_9.gif"
                                     },
                                     {
                                         "name": "scivc_hepatic_veins_visible",
                                         "displayName": "<h4>ANY of the Hepatic veins observed?<br></h4><ul><h4><li><font color=\"yellow\">Yes: Yellow - ANY Hepatic veins observed</font></li><li><font color=\"#BE43F7\">No: Purple - IVC or near IVC + No Hepatic veins observed</font></li><li><font color=\"#0e738f\">Other - Any of:<ul><li>Surrounding anatomy of IVC not visible</li><li>SC 4C</li></ul></font></li></ul></h4>",
                                         "greenClip": "/SC_IVC/scivc_hepatic_veins_visible/SC_Hepatic_vein.gif",
                                         "redClip": "/SC_IVC/scivc_hepatic_veins_visible/1606054500341_1606054504773_9.gif"
                                     }
                                 ]
                             }