import { Typography, Container } from '@mui/material'
export function AnatomyQualityKeyboardShortcutsLegend() {
    return (
        <div style={{ fontSize: '15px', border: '3px solid #4a4a4a', width: '350px'}}>
            <table>
                <tbody>
                    <tr>
                        <td colSpan="2">
                            <b>Keyboard shortcuts</b>
                            <br />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <b>Right Arrow</b>
                            </span>
                        </td>
                        <td>
                            <span>
                                Select next clip
                                <br />
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <b>Left Arrow</b>
                            </span>
                        </td>
                        <td>
                            <span>
                                Select previous clip
                                <br />
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span style={{backgroundColor:'#00ff00'}}>
                                <b>V</b>
                            </span>
                        </td>
                        <td>
                            <span style={{backgroundColor:'#00ff00'}}>
                                Mark clip as 'YES'
                                <br />
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span style={{backgroundColor:'#ff0000'}}>
                                <b>X</b>
                            </span>
                        </td>
                        <td>
                            <span style={{backgroundColor:'#ff0000'}}>
                                Mark clip as 'NO'
                                <br />
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <b>D</b>
                            </span>
                        </td>
                        <td>
                            <span>
                                Save answers & Move to next question
                                <br />
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}
