import { Grid, Box, Container, CircularProgress } from '@mui/material'
import ExamList from '../ExamList'
import { PageBar } from '../PageBar'
export function Quality20ExamsSection({ isLoadingExams, exams, setSelectedExam, selectedExam, pageStates, setPageStates }) {
    return (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
                sx={{
                    pt: 2
                }}>
                <Container maxWidth="lg">
                    {isLoadingExams ? (
                        <Box height="210px" display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: '210px',
                                display: 'none'
                            }}>
                            <ExamList exams={exams} setSelectedExam={setSelectedExam} selectedExam={selectedExam} />
                        </Box>
                    )}
                    <PageBar pageStates={pageStates} setPageStates={setPageStates} />
                </Container>
            </Box>
        </Grid>
    )
}
