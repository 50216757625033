import { useEffect, useRef } from 'react'
import { Box, Divider, Stack, Button, IconButton, Typography } from '@mui/material'
import { PlayArrowRounded, PauseRounded, FastRewindRounded, FastForwardRounded, ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material'
import useClipKeyboardShortcuts from '../../hooks/useClipKeyboardShortcuts'

export default function LabelingCardActions({ playFunction, pauseFunction, changeImage, currentFrame, framesCount, isClipPlaying, setIsClipPlaying, clipSpeed, setClipSpeed }) {
    const playPauseButton = useRef()
    const rewindButton = useRef()
    const oneFrameBackButton = useRef()
    const oneFrameForwardButton = useRef()
    const goToEndButton = useRef()

    // handle state change
    useEffect(() => {
        isClipPlaying ? playFunction() : pauseFunction()
    }, [isClipPlaying])

    function handlePlayPauseClick() {
        isClipPlaying ? setIsClipPlaying(false) : setIsClipPlaying(true)
    }

    function handleRewind() {
        if (!isClipPlaying) changeImage(0)
        else {
            setIsClipPlaying(false)
            changeImage(0)
        }
    }

    function handleGoToEnd() {
        const numOfFramesBeforeEnd = 2
        if (!isClipPlaying) changeImage(framesCount - numOfFramesBeforeEnd)
        else {
            setIsClipPlaying(false)
            changeImage(framesCount - numOfFramesBeforeEnd)
        }
    }

    function handleClipSpeed() {
        // set the next expected speed
        switch (clipSpeed) {
            case 1:
                setClipSpeed(0.75)
                break
            case 0.75:
                setClipSpeed(0.5)
                break
            case 0.5:
                setClipSpeed(0.3)
                break
            case 0.3:
                setClipSpeed(1)
                break
            default:
                setClipSpeed(1)
                break
        }
    }

    useClipKeyboardShortcuts(playPauseButton, rewindButton, oneFrameBackButton, oneFrameForwardButton, goToEndButton)

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Divider orientation="vertical" flexItem />
            <IconButton ref={rewindButton} onClick={handleRewind}>
                <FastRewindRounded />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            {currentFrame === 0 ? (
                <IconButton disabled={true}>
                    <ChevronLeftRounded />
                </IconButton>
            ) : (
                <IconButton
                    disabled={false}
                    ref={oneFrameBackButton}
                    onClick={() => {
                        setIsClipPlaying(false)
                        changeImage(currentFrame - 1)
                    }}>
                    <ChevronLeftRounded />
                </IconButton>
            )}
            <Divider orientation="vertical" flexItem />
            <IconButton ref={playPauseButton} onClick={handlePlayPauseClick}>
                {isClipPlaying ? <PauseRounded /> : <PlayArrowRounded />}
            </IconButton>
            <Divider orientation="vertical" flexItem />
            {currentFrame === framesCount - 1 ? (
                <IconButton disabled={true}>
                    <ChevronRightRounded />
                </IconButton>
            ) : (
                <IconButton
                    disabled={false}
                    ref={oneFrameForwardButton}
                    onClick={() => {
                        setIsClipPlaying(false)
                        changeImage(currentFrame + 1)
                    }}>
                    <ChevronRightRounded />
                </IconButton>
            )}
            <Divider orientation="vertical" flexItem />
            <IconButton ref={goToEndButton} onClick={handleGoToEnd}>
                <FastForwardRounded />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            {framesCount > 0 && (
                <Box sx={{ pl: '15px' }}>
                    <Typography>
                        {currentFrame + 1}/{framesCount}
                    </Typography>
                </Box>
            )}
            <Divider orientation="vertical" flexItem />
            <Button onClick={handleClipSpeed} size="small" sx={{ ml: '10px', bgcolor: 'transparent' }}>
                <Typography>x {clipSpeed}</Typography>
            </Button>
        </Stack>
    )
}
