export const diagnosableQuestions = [
    {
        question: 'Is Clip Diagnosable?',
        questionApiName: 'clip_diagnosable',
        answers: [
            { answer: 'Yes', value: true },
            { answer: 'No', value: false }
        ]
    }
]
