import React, { useState, useEffect, useRef } from 'react'
import { Typography, Card } from '@mui/material'
import LabelingCardActions from './LabelingCardActions'
import CircularProgressWithLabel from '../CircularProgressWithLabel'

function MediaClipLabeling({ currentFrame, setCurrentFrame, screenLocation, frames, selectedExam, setClipsPlayingCounter, isClipPlaying, setIsClipPlaying, clipSpeed, setClipSpeed }) {
    const [imageSrcArray, setImageSrcArray] = useState([]) // video images
    const [loadedFramesCounter, setLoadedFramesCounter] = useState(0) // amount of loaded frames
    const [clipInterval, setClipInterval] = useState(null)
    const cardElement = useRef()
    const imageElement = useRef()
    const loadElement = useRef()

    // reset the states
    useEffect(() => {
        setImageSrcArray([])
        setIsClipPlaying(false)
        setLoadedFramesCounter(0)
        setCurrentFrame(0)
        loadElement.current.replaceChildren()
        resetClip()
    }, [selectedExam])

    // set the frames counter
    useEffect(() => {
        if (frames === null || frames.length === 0) return
        loadImages()
    }, [frames])

    // set the images array
    async function loadImages() {
        if (frames === null || frames.length <= 0) return
        frames.forEach((frame) => {
            const image = document.createElement('img')
            image.setAttribute('src', frame.frame_filename)
            image.addEventListener('load', () => {
                setLoadedFramesCounter((preLoadedFramesCounter) => preLoadedFramesCounter + 1)
            })
            loadElement.current.appendChild(image)
            setImageSrcArray((imageArray) => [...imageArray, frame.frame_filename])
        })
    }

    // the frames are successfully loaded
    useEffect(() => {
        if (frames.length === 0 || loadedFramesCounter === 0) return
        if (loadedFramesCounter !== frames.length) return
        // enable pre-loading
        setClipsPlayingCounter((clipsPlayingCounter) => clipsPlayingCounter + 1)
        // show first image
        imageElement.current.src = imageSrcArray[0]
        cardElement.current.style.display = 'flex'
    }, [loadedFramesCounter, frames])

    useEffect(() => {
        if (isClipPlaying) {
            stopInterval()
            playClip()
        }
    }, [clipSpeed])

    async function playClip() {
        let i = currentFrame
        const loop = setInterval(() => {
            if (i === frames.length) return
            changeImage(i)
            i += 1
        }, Math.floor(35 * (1 / clipSpeed)))
        setClipInterval(loop)
    }

    async function changeImage(index) {
        if (!imageElement.current) return
        imageElement.current.src = imageSrcArray[index]
        setCurrentFrame(index)
    }

    // handle graph interactions
    useEffect(() => {
        if (currentFrame === null) return
        if (!isClipPlaying) imageElement.current.src = imageSrcArray[currentFrame]
    }, [currentFrame])

    async function resetClip() {
        if (clipInterval === null) return
        stopInterval()
        cardElement.current.style.display = 'none'
    }

    async function stopInterval() {
        clearInterval(clipInterval)
        setClipInterval(null)
    }

    return (
        <div>
            {loadedFramesCounter !== 0 && loadedFramesCounter !== frames.length && (
                <div align="center">
                    <CircularProgressWithLabel value={(loadedFramesCounter / frames.length) * 100} />
                </div>
            )}
            <div>
                <Card ref={cardElement} style={{ display: 'none' }} sx={{ height: '100%', display: 'flex', flexDirection: 'column', maxWidth: '580px' }}>
                    {screenLocation && <Typography variant="h6" align="center" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{`${screenLocation} clip`}</Typography>}
                    <img ref={imageElement} src="#" />
                    <LabelingCardActions
                        playFunction={playClip}
                        pauseFunction={stopInterval}
                        changeImage={changeImage}
                        currentFrame={currentFrame}
                        framesCount={frames.length}
                        isClipPlaying={isClipPlaying}
                        setIsClipPlaying={setIsClipPlaying}
                        clipSpeed={clipSpeed}
                        setClipSpeed={setClipSpeed}
                    />
                </Card>
            </div>
            <div ref={loadElement} style={{ visibility: 'hidden', width: '0px', height: '0px', overflow: 'hidden' }} />
        </div>
    )
}
export default React.memo(MediaClipLabeling)
