import { useEffect } from 'react'

export default function useLabelingKeyboardShortcuts(setMarkReferenceView, undoButton, resetSequenceButton, previousSequenceButton, doneButton) {
    const keys = ['2', '3', '4', '5', 'a', 's', 'd', 'f', 'g', 'k', 'z', 'x', 'q', 'r', 't', 'l', 'h', 'e', 'y']
    const values = [
        '2C',
        '3C',
        '4C',
        '5C',
        'PLAX',
        'SAX PAP',
        'SAX MV',
        'SAX AV',
        'RPLAX',
        'SAX APEX',
        'SC 4C',
        'SC IVC',
        'Descending Aorta',
        'Rib',
        'Tissue',
        'Lung',
        'Detached Probe',
        'Between Views',
        'Unknown'
    ]
    const viewKeys = {}

    // adds keydown listener to the whole document
    useEffect(() => {
        keys.forEach((key, i) => (viewKeys[key] = values[i]))
        document.addEventListener('keydown', registerKeyboardEvents)

        return function cleanup() {
            document.removeEventListener('keydown', registerKeyboardEvents)
        }
    }, [])

    function registerKeyboardEvents(event) {
        const keyPressed = event.key
        if (event.ctrlKey || event.shiftKey || event.altKey || event.metaKey) return
        // handle view selection
        if (viewKeys[keyPressed] !== undefined) {
            setMarkReferenceView(viewKeys[keyPressed])
            return
        }
        switch (keyPressed) {
            case 'u':
                undoButton.current?.click()
                break
            case 'o':
                resetSequenceButton.current?.click()
                break
            case 'j':
                previousSequenceButton.current?.click()
                break
            case 'b':
                doneButton.current?.click()
                break
            default:
                return
        }
    }
}
