import { useState, useEffect, createContext } from 'react'
import { CssBaseline, Snackbar } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Amplify, { Auth } from 'aws-amplify'
import Login from './pages/Login'
import UltraSightAppBar from './components/UltraSightAppBar'
import AppRoutes from './components/AppRoutes'

function browserIsChrome() {
    const agent = window.navigator.userAgent.toLowerCase()
    return agent && agent.indexOf('chrome') > -1 && !!window.chrome
}

function isEnglish() {
    const language = window.navigator.userLanguage || window.navigator.language
    return language === 'en-US'
}

Amplify.configure({
    Auth: {
        userPoolId: process.env.REACT_APP_AUTH_USERPOOLID,
        region: 'us-east-2',
        userPoolWebClientId: process.env.REACT_APP_AUTH_USERPOOLWEBCLIENTID
    }
})

const theme = createTheme()
export const Context = createContext()

export default function App() {
    const [showLogin, setShowLogin] = useState(false)
    const [userEmail, setUserEmail] = useState(null)
    const [toastState, setToastState] = useState({ isShowingToast: false, toastContent: null })

    async function authenticateUser() {
        try {
            const userData = await Auth.currentAuthenticatedUser({
                bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            })
            setShowLogin(false)
            console.log(userData)
            setUserEmail(userData.attributes.email)
            Amplify.configure({
                API: {
                    endpoints: [
                        {
                            name: process.env.REACT_APP_API_NAME,
                            endpoint: process.env.REACT_APP_API_ENDPOINT,
                            custom_header: async () => {
                                return {
                                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                                }
                            }
                        }
                    ]
                }
            })
        } catch (err) {
            // The user is no longer authenticated. Needs to login again.
            console.log(err)
            setShowLogin(true)
        }
    }

    useEffect(() => {
        authenticateUser()
    }, [])

    async function getAuthHeaders() {
        if (userEmail === null) {
            return null
        }
        const idToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        return {
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Access-Control-Allow-Headers': "'*'",
                'Access-Control-Allow-Origin': "'*'",
                'Access-Control-Allow-Methods': "'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT'",
                'Access-Control-Allow-Credentials': 'true',
                'X-dev-username': userEmail
            }
        }
    }

    if (!browserIsChrome()) {
        const message = 'Your browser is not supported. Please use Google Chrome.'
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <UltraSightAppBar />
                <h2>{message}</h2>
            </ThemeProvider>
        )
    }

    if (!isEnglish()) {
        const message = 'Your language is not supported. Please use en-US.'
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <UltraSightAppBar />
                <h2>{message}</h2>
            </ThemeProvider>
        )
    }

    function showToast(message) {
        setToastState({ isShowingToast: true, toastContent: message })
    }

    function closeToast() {
        setToastState({ isShowingToast: false, toastContent: null })
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <UltraSightAppBar />
            <Context.Provider value={{ userEmail: userEmail, getAuthHeaders: getAuthHeaders, showToast: showToast }}>
                {showLogin ? <Login authenticateUser={authenticateUser} /> : <AppRoutes />}
            </Context.Provider>
            <Snackbar
                open={toastState.isShowingToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={closeToast}
                message={toastState.toastContent ? toastState.toastContent : 'A problem ocurred'}
            />
        </ThemeProvider>
    )
}
