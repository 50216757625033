import { Button, Stack } from '@mui/material'

export default function AllViews({ setMarkReferenceView }) {
    const parasternalViews = ['PLAX', 'SAX MV', 'SAX AV', 'SAX PAP', 'RPLAX', 'SAX APEX']
    const apicalViews = ['2C', '3C', '4C', '5C']
    const subcostalViews = ['SC 4C', 'SC IVC', 'Descending Aorta']

    return (
        <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
            <Stack direction="row" spacing={1} alignItems="center">
                {parasternalViews.map((label) => (
                    <Button sx={{ backgroundColor: '#80669d' }} size="small" key={label} variant="contained" onClick={(e) => setMarkReferenceView(label)}>
                        {label}
                    </Button>
                ))}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
                {apicalViews.concat(subcostalViews).map((label) => (
                    <Button sx={{ backgroundColor: apicalViews.includes(label) ? '#55c2da' : '#5dbea3' }} size="small" key={label} variant="contained" onClick={(e) => setMarkReferenceView(label)}>
                        {label}
                    </Button>
                ))}
            </Stack>
        </Stack>
    )
}
