import { useEffect, useRef, useContext } from 'react'
import { API } from 'aws-amplify'
import { Context } from '../App'

export default function usePreLoad(loadedClipsCounter, exams, selectedExam, numOfClips, toolType) {
    const AppContext = useContext(Context)
    const preLoadedExamsSet = useRef(new Set())
    const isPreLoading = useRef(false)
    const selectedExamIndex = useRef(null)
    const currentPromise = useRef(null)
    const currentIndex = useRef(null)
    const windowSize = 20
    const apiName = process.env.REACT_APP_API_NAME

    function getEndIndex() {
        return Math.min(selectedExamIndex.current + windowSize, exams.length - 1)
    }

    // cleanup
    function cancelApiRequest() {
        if (currentPromise?.current) {
            API.cancel(currentPromise.current, 'canceled PRE-LOAD REQUEST')
        }
    }

    // checks if preload should happen
    useEffect(() => {
        if (!exams || !getExamOrSequenceId(selectedExam)) {
            return
        }
        // set indices
        selectedExamIndex.current = exams?.indexOf(selectedExam)
        // start pre-loading
        if (loadedClipsCounter === numOfClips) {
            isPreLoading.current = true
            currentIndex.current = selectedExamIndex.current + 1
            fetchImages()
        } else if (loadedClipsCounter === 0) {
            // stop pre-loading
            isPreLoading.current = false
            cancelApiRequest()
        }
    }, [loadedClipsCounter, exams])

    async function fetchImages() {
        let examId = getExamOrSequenceId(exams[currentIndex.current])
        if (preLoadedExamsSet.current.has(examId)) {
            let nextIndex = exams.slice(currentIndex.current).findIndex((exam) => {
                return preLoadedExamsSet.current.has(getExamOrSequenceId(exam)) === false
            })
            if (nextIndex === -1) {
                return
            }
            nextIndex += currentIndex.current
            examId = getExamOrSequenceId(exams[nextIndex])
            currentIndex.current = nextIndex
        }
        cancelApiRequest()
        const requestParams = await AppContext?.getAuthHeaders()
        const path = getRequestPath(examId)
        currentPromise.current = API.get(apiName, path, requestParams)
        try {
            const res = await currentPromise.current
            await preLoadExam(res)
        } catch (err) {
            if (API.isCancel(err)) {
                console.log('request cancelled')
            } else {
                console.log(err)
            }
        }
    }

    // pre-load frames to browser's cache
    async function preLoadExam(frames) {
        const numberOfFrames = frames.length
        let loadedFramesCounter = 0
        frames?.forEach((frame) => {
            let img = new Image()
            img.src = frame.frame_filename
            img.onload = () => {
                loadedFramesCounter++
                if (loadedFramesCounter === numberOfFrames) {
                    const examId = getExamOrSequenceId(exams[currentIndex.current])
                    console.log(`Exam finished pre-loading: ${examId}`)
                    preLoadedExamsSet.current.add(examId)
                    preLoadNext()
                }
            }
        })
    }

    function preLoadNext() {
        currentIndex.current = currentIndex.current + 1
        if (!isPreLoading.current || !currentIndex.current || currentIndex.current > getEndIndex() || !getExamOrSequenceId(exams[currentIndex.current])) return
        fetchImages()
    }

    /*******************************************************************
     * getters by tool type
     *******************************************************************/

    function getExamOrSequenceId(obj) {
        if (['sonographer', 'cardiologist'].includes(toolType)) {
            return obj?.sequenceId
        }
        return obj?.id
    }

    function getRequestPath(id) {
        if (['sonographer', 'cardiologist'].includes(toolType)) {
            return `/api/${toolType}/exams/${selectedExam.examId}/sequences/${id}/images`
        }
        return `/api/quality/${toolType}/exams/${id}/images`
    }
}
