import { Typography, Container } from '@mui/material'
export function QualityViewHeadlineSection({ view }) {
    return (
        <Container
            sx={{
                maxWidth: 'lg'
            }}>
            <div align="center">
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'bold'
                    }}>
                    View: {view}
                </Typography>
            </div>
        </Container>
    )
}
