export const compareQuestions = [
    {
        question: 'Which clip is better',
        questionApiName: 'whos_better',
        answers: [
            { answer: 'Left', value: 1 },
            { answer: 'Equal', value: 0 },
            { answer: 'Right', value: 2 }
        ]
    }
]
