import { Typography, Container, Box, Card, CardMedia } from '@mui/material'
import parse from 'html-react-parser'
export function AnatomyQualityQuestionSection({ sequenceView, question }) {
    const viewDisplayName = {
        'PLAX': 'PLAX', 
        'SAX_PAP': 'Short Axis Papilary Muscles', 
        'SAX_MV': 'Short Axis Mitral Valve', 
        'SAX_AV': 'Short Axis Aortic Valve', 
        '4C': 'Apical 4C', 
        '2C': 'Apical 2C',
        '3C': 'Apical 3C', 
        '5C': 'Apical 5C',
        'SC_4C': 'Subcostal 4C', 
        'SC_IVC': 'Subcostal IVC'
    }
    return (
        <div align="left" style={{border: '4px solid #4a4a4a', display: 'flex', alignItems: 'start', justifyContent: 'space-between', margin: '1px'}} width="80%">
            <Typography style={{ userSelect: 'none', paddingLeft: "5px" }}>
                {question && parse(question.displayName)}
            </Typography>
        </div>
    )
}
