import { Typography, Container, Box, Card, CardMedia } from '@mui/material'
import parse from 'html-react-parser'
export function Quality20QuestionSection({ sequenceView, question }) {
    const viewDisplayName = {
        'PLAX': 'PLAX', 
        'SAX_PAP': 'Short Axis Papilary Muscles', 
        'SAX_MV': 'Short Axis Mitral Valve', 
        'SAX_AV': 'Short Axis Aortic Valve', 
        '4C': 'Apical 4C', 
        '2C': 'Apical 2C',
        '3C': 'Apical 3C', 
        '5C': 'Apical 5C',
        'SC_4C': 'Subcostal 4C', 
        'SC_IVC': 'Subcostal IVC'
    }
    return (
        <Container
            sx={{
                maxWidth: 'lg'
            }}>
            <div align="left" style={{backgroundColor: '#94D2F6', border: '4px solid #4a4a4a', display: 'flex', alignItems: 'start', justifyContent: 'space-between', margin: '1px'}} width="70%">
                <Typography style={{ userSelect: 'none', paddingLeft: "5px" }}>
                    {question && parse(question.displayName)}
                </Typography>
                <div style={{ display: 'flex', padding: "6px"}}>
                    <Box height="175px" width="250px">
                        <Card sx={{ border: `solid yellow 6px` }}>
                            <CardMedia component="img" image={question && parse(question.greenClip)} id='greenClipId' style={{ height: '163px' }} />
                        </Card>
                    </Box>
                    <Box height="175px" width="250px">
                        <Card sx={{ border: `solid #be43f7 6px` }}>
                                <CardMedia component="img" image={question && parse(question.redClip)} id='redClipId' style={{ height: '163px' }} />
                        </Card>
                    </Box>
                </div>
            </div>
        </Container>
    )
}
